import { Logtail } from '@logtail/browser';
import axios from 'axios';

// Get Logtail source token
const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_SOURCE_TOKEN);

// Log errors and send to Logtail
export const logErrors = async error => {
  // Get user IP address
  const ipAddress = await axios.get('https://api.ipify.org/?format=json');

  if (error) {
    logtail.error(
      `${error}. URL: ${window.location.href}. IP: ${ipAddress.data.ip}`
    );
    logtail.flush();
  }
};
