import AddressInput from 'components/common/inputs/AddressInput';
import FlagPhoneInput from 'components/common/phone-input/FlagPhoneInput';
import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatAddress } from '../../../../../helpers/utils';
import WizardInput from '../../../../auth/register/WizardInput';

const AddressForm = ({ formMethods, customer }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    control
  } = formMethods;
  useEffect(() => {
    if (customer?._id) {
      const { f_name, l_name, phone } = getValues();
      setValue('f_name', f_name || customer?.first_name);
      setValue('l_name', l_name || customer?.last_name);
      setValue('phone', phone || customer?.phone_number);
    }
  }, [customer]);

  const onAddressChange = value => {
    setValue('address', formatAddress(value));
  };

  return (
    <Row className="gy-3 mt-1">
      <WizardInput
        name="firstName"
        errors={errors}
        formGroupProps={{ as: Col, xs: 12, md: 6, controlId: 'firstName' }}
        formControlProps={{
          placeholder: 'First name',
          ...register('f_name')
        }}
      />

      <WizardInput
        name="lastName"
        errors={errors}
        formGroupProps={{ as: Col, xs: 12, md: 6, controlId: 'lastName' }}
        formControlProps={{
          placeholder: 'Last name',
          ...register('l_name')
        }}
      />

      <Controller
        name="address"
        control={control}
        render={({ field }) => {
          const location = field?.value?.location;

          return (
            <AddressInput
              isInvalid={errors?.address}
              onChange={onAddressChange}
              value={{ label: location }}
              required
            />
          );
        }}
      />

      <Form.Group as={Col} xs={12} controlId={'phone'}>
        <FlagPhoneInput
          name="phone"
          control={control}
          errors={errors}
          placeholder="214 123 4567"
          style={{ width: '100%' }}
        />
        <Form.Control.Feedback
          style={{
            display: errors['phone'] ? 'block' : 'none'
          }}
          type="invalid"
        >
          {errors?.['phone'] && 'Invalid phone number'}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Col} xs={12} className="mb-3" controlId="label">
        <Form.Select
          name="className"
          {...register('type')}
          isInvalid={errors['type']}
        >
          <option value="">Address type</option>
          <option value="business">Business</option>
          <option value="residential">Residential</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors?.['type'] && 'Type is required'}
        </Form.Control.Feedback>
      </Form.Group>
    </Row>
  );
};

export default AddressForm;
