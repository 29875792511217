import { serializeSearchParams } from 'helpers/utils';
import { useSearchParams } from 'react-router-dom';

/**
 * hook that get sort key on search params
 * @returns {Object} object contains params
 * object and params change handler
 */
export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = serializeSearchParams(searchParams);

  if (params?.is_recommended)
    params.is_recommended = params?.is_recommended === 'true' ? 1 : 0;

  const handleParamChange = field => value => {
    setSearchParams(prev => {
      const params = serializeSearchParams(prev);
      if (value === undefined || value === null) {
        delete params[field];
        return params;
      } else return { ...params, [field]: value };
    });
  };

  const handleParamsChange = newParams => {
    if (typeof params !== 'object') return;
    setSearchParams(
      prev => {
        const prevParams = serializeSearchParams(prev);
        const params = { ...prevParams, ...newParams };
        for (const prop in params) {
          if (params[prop] === undefined || params[prop] === null) {
            delete params[prop];
          }
        }
        return params;
      },
      { replace: true }
    );
  };

  return { params, handleParamChange, handleParamsChange };
};
