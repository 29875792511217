import classNames from 'classnames';
import React from 'react';
import AsyncSelect from 'react-select/async';

const AsyncSelectInput = React.forwardRef(({ isInvalid, ...props }, ref) => {
  return (
    <AsyncSelect
      {...props}
      isDisabled={props.disabled}
      ref={ref}
      clearIndicator
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      styles={{
        input: baseStyles => ({
          ...baseStyles,
          padding: 0
        }),
        clearIndicator: baseStyles => ({ ...baseStyles, padding: 0 }),
        valueContainer: baseStyles => ({
          ...baseStyles,
          padding: '0 !important'
        }),
        control: baseStyles => ({
          ...baseStyles,
          backgroundColor: props.disabled ? '#f5f5f5 !important' : undefined
        })
      }}
      classNames={{
        control: () =>
          classNames({
            'react-select__control--invalid': isInvalid,
            'form-control': true
          })
      }}
    />
  );
});

export default AsyncSelectInput;
