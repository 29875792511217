import { useMutation } from '@tanstack/react-query';
import { apiCallFile } from './ApiCall';
import { apiCallV2 } from './ApiCallV2';

/**
 * Upload .ZIP file containing product images using `useMutation` from `@tanstack/react-query`
 * product_zip (required)
 * @param {Object} data - Object with ZIP file.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useUploadZipImg = () => {
  const mutation = useMutation((data) => {
    const controller = new AbortController();
    const url = 'products/upload_all_media_zip';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

/**
 * Import Excel using `useMutation` from `@tanstack/react-query`
 * @param {string} resourceName - resource name
 * @returns {object} The `useQuery` object with data, status, error, etc.
 */
export const useImportExcel = (resourceName) => {
  const mutation = useMutation((data) => {
    const controller = new AbortController();
    const url = `${resourceName}/add_${resourceName}_excel`;
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

export const useArrangeItems = (model) => {
  const mutation = useMutation({
    mutationFn: (items = []) => {
      const controller = new AbortController();
      const url = 'admin/change-positions';

      return apiCallV2({
        method: 'PUT',
        url,
        data: { model, items },
        signal: controller.signal,
      });
    },
  });

  return mutation;
};
