import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';

const LoadingButton = ({
  disabled,
  children,
  isLoading,
  onClick,
  variant = 'primary',
  size = 'md',
  ...rest
}) => {
  return (
    <IconButton
      {...rest}
      variant={variant}
      icon={isLoading ? 'spinner' : undefined}
      transform="shrink-3"
      onClick={onClick}
      disabled={!!isLoading || disabled}
      spin={!!isLoading}
      size={size}
    >
      {children}
    </IconButton>
  );
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func
};

export default LoadingButton;
