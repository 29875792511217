export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// Types for salerep carts call
export const GETTING_SALEREP_CARTS_DETAILS_DATA =
  'GETTING_SALEREP_CARTS_DETAILS_DATA';
export const SAVE_SALEREP_CARTS_DETAILS_SUCCESS =
  'SAVE_SALEREP_CARTS_DETAILS_SUCCESS';
export const SAVE_SALEREP_CARTS_DETAILS_FAILURE =
  'SAVE_SALEREP_CARTS_DETAILS_FAILURE';

// Types for salerep carts call
export const GETTING_SALEREP_CARTS_DATA = 'GETTING_SALEREP_CARTS_DATA';
export const SAVE_SALEREP_CARTS_SUCCESS = 'SAVE_SALEREP_CARTS_SUCCESS';
export const SAVE_SALEREP_CARTS_FAILURE = 'SAVE_SALEREP_CARTS_FAILURE';

// Types for carts call
export const GETTING_CARTS_DATA = 'GETTING_CARTS_DATA';
export const SAVE_CARTS_SUCCESS = 'SAVE_CARTS_SUCCESS';
export const SAVE_CARTS_FAILURE = 'SAVE_CARTS_FAILURE';

// Types for orders call
export const GETTING_ORDERS_DATA = 'GETTING_ORDERS_DATA';
export const SAVE_ORDERS_SUCCESS = 'SAVE_ORDERS_SUCCESS';
export const SAVE_ORDERS_FAILURE = 'SAVE_ORDERS_FAILURE';

// Types for order detail call
export const GETTING_ORDER_DETAIL_DATA = 'GETTING_ORDER_DETAIL_DATA';
export const SAVE_ORDER_DETAIL_SUCCESS = 'SAVE_ORDER_DETAIL_SUCCESS';
export const SAVE_ORDER_DETAIL_FAILURE = 'SAVE_ORDER_DETAIL_FAILURE';

// Type for payments call
export const GETTING_PAYMENTS_DATA = 'GETTING_PAYMENTS_DATA';
export const SAVE_PAYMENTS_SUCCESS = 'SAVE_PAYMENTS_SUCCESS';
export const SAVE_PAYMENTS_FAILURE = 'SAVE_PAYMENTS_FAILURE';

// Type for products call
export const GETTING_PRODUCT_DATA = 'GETTING_PRODUCT_DATA';
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_CHART_SUCCESS';
export const SAVE_PRODUCT_FAILURE = 'SAVE_PRODUCT_CHART_FAILURE';

// Type for dashboard_chart call
export const GETTING_DASHBOARD_DATA = 'GETTING_DASHBOARD_DATA';
export const SAVE_SELLER_DASHBOARD_CHART_SUCCESS =
  'SAVE_SELLER_DASHBOARD_CHART_SUCCESS';
export const SAVE_SELLER_DASHBOARD_CHART_FAILURE =
  'SAVE_SELLER_DASHBOARD_CHART_FAILURE';
export const SAVE_ADMIN_DASHBOARD_CHART_SUCCESS =
  'SAVE_ADMIN_DASHBOARD_CHART_SUCCESS';
export const SAVE_ADMIN_DASHBOARD_CHART_FAILURE =
  'SAVE_ADMIN_DASHBOARD_CHART_FAILURE';
// commit test
// images
export const UPDATE_APP_IMAGE = 'UPDATE_APP_IMAGE';
