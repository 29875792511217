import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from 'actions/types';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? { isLoggedIn: true, user, visited: true }
  : { isLoggedIn: false, user: null, visited: false };

export default function (state = initialState, action) {
  const { type, payload } = action;

  const user = payload?.user;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        visited: false
      };
    default:
      return state;
  }
}
