import { Tabs, Text, Title } from '@mantine/core';
import {
  SectionBody,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import CustomerOrderTab from './CustomerOrderTab';
import ReferralHistoryTab from './ReferralHistoryTab';
type PropType = {
  children?: ReactNode;
  className?: string;
  customer?: any;
};
const CustomerTabsCard = (props: PropType) => {
  // ### CONSTANTs
  const { customer } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <SectionWrapper>
      <SectionBody>
        <Tabs defaultValue="orders">
          <Tabs.List>
            <Tabs.Tab value="orders">
              <Text size="lg">{t('orders.orders')}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="referral-history">
              <Text size="lg">{t('customer.referrals')}</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="orders" className="pt-2">
            <CustomerOrderTab />
          </Tabs.Panel>

          <Tabs.Panel value="referral-history" className="pt-2">
            <ReferralHistoryTab />
          </Tabs.Panel>
        </Tabs>
      </SectionBody>
    </SectionWrapper>
  );
};
export default CustomerTabsCard;
