import { useMutation } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCall';
import { toast } from 'react-toastify';

/**
 * Handles the mutation logic for submitting data and provides success and error callbacks.
 * @param {func} mutate A function for triggering the mutation.
 * @param {object} data An object containing the data for the dropdown item.
 * @param {func} refetch  A function for refetching data after successful mutation.
 * @param {func} onSuccess A function for handling the success callback.
 */
export const handleMutation = (submitData, mutate, onSuccess) => {
  try {
    mutate(submitData, {
      onSuccess: res => {
        if (!res.status) return toast.error(`${res.message}`);
        toast.success(`${res.message}`);
        onSuccess && onSuccess();
      }
    });
  } catch (err) {
    toast.error(`${err.message}`);
  }
};
export const useCreateCustomer = () => {
  return useMutation({
    mutationFn: data => {
      return apiCallV2({
        method: 'POST',
        url: 'admin/customers',
        data
      });
    }
  });
};
export const useUpdateCustomer = () => {
  return useMutation({
    mutationFn: data => {
      const { id, ...params } = data;
      return apiCallV2({
        method: 'PUT',
        url: 'admin/customers/' + id,
        data: { ...params }
      });
    }
  });
};

/**
 * Handles the status change in a dropdown.
 * @param {func} mutate A function for triggering the mutation.
 * @param {string} id_name The name of the identifier property in the `data` object.
 * @param {object} data An object containing the data for the dropdown item.
 * @param {func} onSuccess  A function for handling the success callback.
 */
export const changeStatusDropdown = (mutate, id_name, data, onSuccess) => {
  try {
    const submitData = {
      [id_name]: data?._id,
      status: data?.status == '0' ? '1' : '0'
    };

    handleMutation(submitData, mutate, onSuccess);
  } catch (err) {
    toast.error(`${err.message}`);
    throw new Error(err);
  }
};

/**
 * Handles the deletion of an item from a dropdown.
 * @param {func} mutate A function for triggering the mutation.
 * @param {string} id_name The name of the identifier property in the `data` object.
 * @param {object} data An object containing the data for the dropdown item.
 * @param {func} onSuccess  A function for handling the success callback.
 */
export const deleteDropdown = (mutate, id_name, data, onSuccess) => {
  try {
    const submitData = {
      [id_name]: data?._id,
      status: '2'
    };

    handleMutation(submitData, mutate, onSuccess);
  } catch (err) {
    throw new Error(err);
  }
};
