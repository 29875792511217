import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { Button, Flex, Text, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteCustomer, useSendEmailResetPass } from 'apis/CustomersApi';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import CommonAvatar from 'components/MantineUI/CommonAvatar';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { phoneFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { getCustomerTitle } from 'pages/customers';
import CustomerDetailsModal from 'pages/customers/components/CustomerDetailsModal';
import DeleteCustomerModal from 'pages/customers/components/DeleteCustomerModal';
import { userKeys } from 'pages/customers/queryKeys';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
type PropType = {
  customer?: any;
};
const CustomerInfoCard = (props: PropType) => {
  // ### CONSTANTs
  const { customer } = props;
  const {
    ref_code,
    email,
    business_name,
    business_phone,
    phone_number,
    title_type,
  } = customer || {};
  // ### RENDERs
  const InfoList = [
    {
      label: t('customer.referral_code'),
      value: ref_code,
      copy: true,
    },
    {
      label: 'Email',
      value: email,
      copy: true,
    },
    {
      label: t('general.phone'),
      value: phoneFormatter(phone_number),
      copy: true,
    },
    {
      label: t('customer.business_name'),
      value: business_name,
      copy: true,
    },
    {
      label: t('customer.business_phone'),
      value: business_phone,
      copy: true,
    },
    {
      label: t('customer.role_type'),
      value: getCustomerTitle(title_type),
      copy: false,
    },
    {
      label: t('customer.auth_provider'),
      value: '',
      copy: false,
    },
  ];

  return (
    <SectionWrapper>
      <SectionHeader
        isSubSection
        title={t('navigation.information')}
      ></SectionHeader>
      <SectionBody>
        {InfoList?.map((info, index) => {
          return (
            <div key={index} className="mb-3">
              <Text size="sm" c={'gray'}>
                {info?.label}
              </Text>
              <Flex align={'center'} justify={'space-between'}>
                {info?.value ? (
                  <>
                    <Text>{info?.value}</Text>
                    {info?.copy ? <CopyButton value={info?.value} /> : null}
                  </>
                ) : (
                  '--'
                )}
              </Flex>
            </div>
          );
        })}
      </SectionBody>
    </SectionWrapper>
  );
};
export default CustomerInfoCard;
