import {
  SAVE_SELLER_DASHBOARD_CHART_SUCCESS,
  SAVE_SELLER_DASHBOARD_CHART_FAILURE,
  SAVE_ADMIN_DASHBOARD_CHART_SUCCESS,
  SAVE_ADMIN_DASHBOARD_CHART_FAILURE,
  GETTING_DASHBOARD_DATA
} from 'actions/types';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log('🚀 ~ file: dashboardChartReducer.js:17 ~ payload:', payload);

  switch (type) {
    case GETTING_DASHBOARD_DATA:
      return { ...state, isLoading: true };

    case SAVE_SELLER_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.response,
        error: null
      };
    case SAVE_ADMIN_DASHBOARD_CHART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.response,
        error: null
      };

    case SAVE_SELLER_DASHBOARD_CHART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    case SAVE_ADMIN_DASHBOARD_CHART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
}
