import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'contexts/AuthContext';
import { useSignInWithEmailLink } from 'hooks/firebase-authentication';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const EmailLinkLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const location = useLocation();
  const params = queryString.parse(location.search);
  const isSignIn = params?.mode === 'signIn';
  const email = location.state?.email || params.email;

  const [loading, setLoading] = useState(false);

  const { signIn } = useSignInWithEmailLink();

  const handleLogin = async () => {
    setLoading(true);
    try {
      await signIn(email);
      navigate('/');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSignIn) return;

    if (!email) return toast.error('Invalid email');

    handleLogin();
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <Row className="flex-center">
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Card.Body className="px-md-6 py-3">
            {isSignIn ? (
              <div className="d-flex flex-column">
                <h4 className="text-center">Sign in via mail link</h4>
                {loading && (
                  <p className="text-center mt-2 mb-0 text-primary fs--1">
                    Processing...
                    <br />
                    <FontAwesomeIcon spin icon="spinner" />
                  </p>
                )}
              </div>
            ) : (
              <div className="d-flex flex-column">
                <h4 className="text-center">
                  Check your email's inbox to verify your account
                </h4>
                <p className="text-center mt-2 mb-0 fs--1">
                  Verification link is sent to email
                </p>
                {email && (
                  <p className="text-center text-primary fs--1">{email}</p>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default EmailLinkLogin;
