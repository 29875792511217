import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportLink } from 'config';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Error403 = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('error.error_403'));

  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">403</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {t('error.error_403_message')}
        </p>
        <hr />
        <p>
          {t('error.error_403_content')}
          <Link to={supportLink} target="_blank" className="ms-1">
            {t('error.contact_us')}
          </Link>
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome} className="me-2" />
          {t('error.back_home')}
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error403;
