import { ShippingInformationTypeEnum } from 'constants/orders';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTrackingUrl, getCarrierName } from 'helpers/utils';
import { OrderStatusEnum } from 'types';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { Alert, Anchor, Badge, Button, Flex, Grid, Text } from '@mantine/core';
import ShippingLabelItems from './ShippingLabelItems';
import PackageParcelItems from './PackageParcelItems';
import IconFA from 'components/common/IconFA';

const FulfillSummary = ({ data }: any) => {
  const { t } = useTranslation();
  const shipment = data?.shipment;
  const isFulfilled =
    data?.order_status !== OrderStatusEnum.PROCESSING &&
    shipment &&
    Object.keys(shipment).length > 0;
  const isShippo = shipment?.type === ShippingInformationTypeEnum.Shippo;
  const isManual = shipment?.type === ShippingInformationTypeEnum.Manual;
  const isCustom = shipment?.type === ShippingInformationTypeEnum.Custom;

  const renderShippo = () => {
    return (
      <>
        <Grid.Col span={{ base: 12, lg: 'auto' }}>
          <Text fw={'600'}>{t('orders.shipping_label')}</Text>
          <ShippingLabelItems shipment={shipment} />
        </Grid.Col>

        {shipment?.parcels?.length ? (
          <Grid.Col span={{ base: 12, lg: 6 }}>
            <Text className="mb-2" fw={'600'}>
              {t('orders.package_info')}
            </Text>
            <PackageParcelItems parcels={shipment?.parcels} />
          </Grid.Col>
        ) : null}
      </>
    );
  };
  const shippo_track = shipment?.shippo_track;
  const renderManual = () => {
    return (
      <>
        <Grid.Col span={{ base: 12, lg: 'auto' }}>
          <Text fw={'600'}>{t('orders.tracking_number')}</Text>
          <div>
            <Flex align={'center'}>
              <Anchor
                size="sm"
                target="_blank"
                fw={'600'}
                component={Link}
                to={getTrackingUrl(
                  shipment?.carrier_id,
                  shipment?.tracking_number,
                )}
              >
                {shipment?.tracking_number}
              </Anchor>
              <CopyButton value={shipment?.tracking_number} />
              {shippo_track?.tracking_status?.status && (
                <Badge color="blue" variant="light">
                  {shippo_track?.tracking_status?.status}
                </Badge>
              )}
            </Flex>
            <Text size="sm">
              {getCarrierName(shipment?.carrier_id)}{' '}
              {shippo_track?.servicelevel?.name}{' '}
            </Text>
            {shippo_track?.tracking_status?.status_details ? (
              <>
                <Text size="sm">
                  {t('general.status')}:{' '}
                  {shippo_track?.tracking_status?.status_details}
                </Text>
                {shippo_track?.tracking_status?.substatus?.action_required ? (
                  <Alert
                    icon={<IconFA icon={'triangle-exclamation'} />}
                    color="red"
                    className="my-2"
                  >
                    {shippo_track?.tracking_status?.substatus?.text}
                  </Alert>
                ) : null}
              </>
            ) : null}
          </div>
        </Grid.Col>
      </>
    );
  };
  return (
    <React.Fragment>
      {isFulfilled ? (
        <>
          <Grid className="mx-1 my-3 p-3 bg-200 rounded-3 gap-4">
            {isShippo ? (
              renderShippo()
            ) : isManual ? (
              renderManual()
            ) : isCustom ? (
              <Grid.Col span={{ base: 12, md: 12 }}>
                <Text fw={'600'}>{t('orders.custom_fulfillment')}</Text>
                <Text size="sm">{shipment?.note ?? ''}</Text>
              </Grid.Col>
            ) : null}
          </Grid>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default FulfillSummary;
