import { StatusEnum } from 'types';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { ComboboxItem, Flex, Text } from '@mantine/core';
type Props = ComboboxItem & {
  label: string;
  value: string;
  status: StatusEnum;
  isSelected?: boolean;
};

export const RenderOptionWithStatus = (props: Props) => {
  const { label, status, isSelected } = props;
  return (
    <Flex gap={'xs'} align={'center'}>
      <Text size="sm" c={isSelected ? 'primary' : ''}>
        {label}
      </Text>
      {status && status !== StatusEnum.ACTIVE && (
        <StatusBadge status={status} />
      )}
    </Flex>
  );
};

export default RenderOptionWithStatus;
