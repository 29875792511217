import {
  SAVE_ORDERS_SUCCESS,
  SAVE_ORDERS_FAILURE,
  GETTING_ORDERS_DATA
} from 'actions/types';

const initialState = {
  data: null,
  isLoading: null,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTING_ORDERS_DATA:
      return { ...state, isLoading: true };

    case SAVE_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.response,
        error: null
      };
    case SAVE_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    default:
      return state;
  }
}
