import { CommonTableColumn } from 'types';
import { useGetOrders } from 'apis/OrdersApi';
import PaymentStatus from 'components/common/PaymentStatus';
import {
  PlaceholderTable,
  PlaceholderTableNoHeader,
} from 'components/common/PlaceHolder';
import Error500 from 'components/errors/Error500';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PAGE,
  DEFAULT_PAGE_LIMIT,
} from 'constants/constants';
import dayjs from 'dayjs';
import { currencyFormatter, dateTimeFormatter } from 'helpers/utils';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Anchor } from '@mantine/core';
import OrderStatus from 'pages/orders/components/OrderStatus';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { CommonTable } from 'components/MantineUI/CommonTable';

type Props = {
  customer?: any;
};

const CustomerTabsCard = ({ customer }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { params, handleParamsChange }: any = useURLParams();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const { isLoading, error, data }: any = useGetOrders({
    ...params,
    page,
    customer_id: id,
    limit,
  });
  const orders = data?.data?.results ?? [];
  const total = data?.data?.total ?? 0;

  const columns: CommonTableColumn[] = useMemo(
    () => [
      {
        accessor: 'order_id',
        Header: t('orders.order_number'),
        Cell: (row: any) => {
          const { code, _id } = row;
          return (
            <Anchor component={Link} to={`/orders/${_id}`} size="sm">
              {code}
            </Anchor>
          );
        },
      },
      {
        accessor: 'created_on',
        Header: t('orders.order_date'),
        Cell: (rowData: any) => dateTimeFormatter(rowData?.created_on),
      },
      {
        Cell: (row: any) =>
          row?.pricing?.seller?.store_name ? (
            <Anchor
              component={Link}
              to={`/sellers/${row?.pricing?.seller?._id}`}
              size="sm"
            >
              {row?.pricing?.seller?.store_name}
            </Anchor>
          ) : (
            '--'
          ),
        accessor: 'store',
        Header: t('orders.store'),
      },
      {
        accessor: 'total_items',
        Cell: (row: any) => `x ${row?.total_items}`,
        Header: t('orders.items'),
      },
      {
        accessor: 'total',
        Cell: (row: any) => currencyFormatter.format(row?.pricing?.total || 0),
        Header: t('orders.total'),
      },
      {
        accessor: 'payment_status',
        Header: t('orders.payment_status'),
        Cell: (rowData: any) => {
          const { payment_status } = rowData;
          return <PaymentStatus fullWidth status={payment_status} />;
        },
      },
      {
        accessor: 'order_status',
        Header: t('orders.order_status'),
        Cell: (row: any) => {
          const { order_status } = row;
          return <OrderStatus status={order_status} />;
        },
      },
    ],
    [data],
  );

  return (
    <CommonTable
      data={orders}
      columns={columns}
      pagination={{ page, limit, onPaginationChange, total }}
    />
  );
};

export default CustomerTabsCard;
