import NailzyImage from 'components/common/NailzyImage';
import { getColor } from 'helpers/utils';
import React from 'react';
import { Link } from 'react-router-dom';
const OrderItem = ({ item }) => {
  const twoLineStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  };
  return (
    <div className="d-flex flex-row align-items-center">
      <NailzyImage
        style={{
          width: 64,
          height: 64,
          border: '1px solid #f0f0f0',
          borderRadius: 4
        }}
        image_url={item?.image_urls?.[0]}
      />
      <div className="ms-2">
        <div
          style={{
            ...twoLineStyles,
            fontSize: 13,
            color: getColor('primary')
          }}
        >
          <Link to={`/products/${item?.product_id}`}>{item?.product_name}</Link>
        </div>
        <div style={{ ...twoLineStyles, fontSize: 13 }}>
          {item?.variant_name || ''}
        </div>
        {item?.sku && (
          <div style={{ fontSize: 13 }}>SKU: {item?.sku || ''}</div>
        )}
      </div>
    </div>
  );
};
export default OrderItem;
