import {
  Button,
  DefaultMantineColor,
  FloatingPosition,
  MantineTransition,
  Menu,
  StyleProp,
} from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type DropdownDataTrueType = {
  key: string;
  label: string;
  isDivider?: false;
  disabled?: boolean;
  color?: StyleProp<DefaultMantineColor>;
  onClick?: (key: string) => void;
  href?: string;
  to?: string;
  hide?: boolean;
};
type DropdownDataType =
  | {
      isDivider?: true;
      hide?: boolean;
    }
  | DropdownDataTrueType;

type Props = {
  button?: ReactNode;
  dropdownData: DropdownDataType[];
  disabled?: boolean;
  position?: FloatingPosition;
  transition?: MantineTransition;
  selected?: string;
  onSelect?: (key: string) => void;
};

const DropdownButton = ({
  button,
  dropdownData = [],
  disabled,
  position = 'bottom-end',
  transition = 'pop-top-right',
  selected = '',
  onSelect,
}: Props) => {
  const navigate = useNavigate();
  const renderItem = (item: DropdownDataTrueType, i: number) => {
    if (item?.hide) return;
    if (item?.isDivider) return <Menu.Divider key={i} />;
    const { onClick, href, key, to } = item;
    return (
      <Menu.Item
        disabled={item?.disabled}
        className="px-3"
        key={i}
        onClick={() => {
          if (!!onSelect && key !== selected) {
            onSelect(key);
          }
          if (onClick) {
            onClick?.(key);
          }
          if (href) {
            window.open(href);
          }
          if (to) {
            navigate(to);
          }
        }}
        c={item?.color || (item?.key === selected ? 'primary' : '')}
      >
        {item?.label}
      </Menu.Item>
    );
  };

  return (
    <Menu
      shadow="md"
      disabled={disabled}
      transitionProps={{ transition: transition }}
      position={position}
    >
      <Menu.Target>
        {button ? (
          button
        ) : (
          <Button variant="subtle" color="gray" size="xs">
            <IconFA icon={'ellipsis'} />
          </Button>
        )}
      </Menu.Target>
      <Menu.Dropdown>
        {dropdownData?.map((item: DropdownDataType, i: number) =>
          renderItem(item as DropdownDataTrueType, i),
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default DropdownButton;
