import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { company, version } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let count = 0;

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center align-items-center fs--1 mt-2 mb-4 gy-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          &copy; {new Date().getFullYear()} {company}.{' '}
          <span className="ms-1">v{version}</span>. Render: {count++}
        </p>
      </Col>

      <Col sm="auto">
        <Row className="g-2 justify-content-center">
          <Col xs="auto">
            <Button
              variant="light"
              size="sm"
              className="btn-outline-dark"
              href={'https://apps.apple.com/us/app/nailzy-com/id6443856295'}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={['fab', 'apple']}
                transform="grow-12"
                className="me-2"
              />
              <span className="d-inline-block text-start align-middle lh-sm">
                <small className="d-block">Download on the</small>
                App Store
              </span>
            </Button>
          </Col>

          <Col xs="auto">
            <Button
              variant="light"
              size="sm"
              className="btn-outline-dark"
              href={
                'https://play.google.com/store/apps/details?id=com.nailsa2z'
              }
              target="_blank"
            >
              <FontAwesomeIcon
                icon={['fab', 'google-play']}
                transform="grow-8"
                className="me-2"
              />
              <span className="d-inline-block text-start align-middle lh-sm">
                <small className="d-block">GET IT ON</small>
                Google Play
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </footer>
);

export default Footer;
