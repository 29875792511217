import { createContext, useContext, useEffect, useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

const TopLoadingContext = createContext({});

export const TopLoadingProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState();
  const ref = useRef(null);
  useEffect(() => {
    if (isFetching === undefined) return;
    if (isFetching) ref.current.continuousStart();
    else ref.current.complete();
  }, [isFetching]);
  return (
    <TopLoadingContext.Provider value={{ setIsFetching }}>
      {children}
      <LoadingBar color="#ff0088" ref={ref} />
    </TopLoadingContext.Provider>
  );
};

export const useTopLoading = () => useContext(TopLoadingContext);
