import { isString, keysFromObject } from 'helpers/utils';
import { TFunction } from 'i18next';
import { Fields } from 'react-spreadsheet-import/types/types';

export type TFieldsKey =
  | 'store_name'
  | 'brand_name'
  | 'category'
  | 'subcategory'
  | 'product_name'
  | 'product_description'
  | 'variant_name'
  | 'variant_description'
  | 'sku'
  | 'weight'
  | 'weight_unit'
  | 'length'
  | 'width'
  | 'height'
  | 'dimension_unit'
  | 'barcode'
  | 'size'
  | 'marterial'
  | 'inventory'
  | 'inventory_low_level'
  | 'original_price'
  | 'price'
  | 'main_image'
  | 'image_1'
  | 'image_2'
  | 'image_3'
  | 'image_4'
  | 'image_5'
  | 'image_6'
  | 'image_7'
  | 'image_8'
  | 'status';

export type TFieldData = Array<[string, TFieldsKey, string]>;

/**
 * Array data type for xlxs
 * element includes [header , column id, example]
 */
export const fieldData: TFieldData = [
  ['Store Name', 'store_name', 'nailzy'],
  ['Brand Name', 'brand_name', 'OPI'],
  ['Category', 'category', 'Eye & Face'],
  ['Subcategory', 'subcategory', 'Waxing'],
  ['Product Name', 'product_name', 'Product ABC'],
  ['Product Description', 'product_description', 'Skin Type: All Skins'],
  ['Variant Name', 'variant_name', 'Variant XYZ'],
  ['Variant Description', 'variant_description', '...'],
  ['SKU', 'sku', 'SKU123'],
  ['Weight', 'weight', '1.76'],
  ['Weight Unit', 'weight_unit', 'oz'],
  ['Length', 'length', '1.77'],
  ['Width', 'width', '1.77'],
  ['Height', 'height', '1.46'],
  ['Dimension Unit', 'dimension_unit', 'in'],
  ['Barcode', 'barcode', '...'],
  ['Size', 'size', '...'],
  ['Material', 'marterial', '...'],
  ['Inventory', 'inventory', '20'],
  ['Inventory Low Level', 'inventory_low_level', '5'],
  ['Original Price', 'original_price', '$10'],
  ['Price', 'price', '$6.95'],
  ['Main Image', 'main_image', 'https://image.jpg'],
  ['Image 1', 'image_1', 'https://image.jpg'],
  ['Image 2', 'image_2', '...'],
  ['Image 3', 'image_3', '...'],
  ['Image 4', 'image_4', '...'],
  ['Image 5', 'image_5', '...'],
  ['Image 6', 'image_6', '...'],
  ['Image 7', 'image_7', '...'],
  ['Image 8', 'image_8', '...'],
  ['Status', 'status', 'Active'],
];

export const requireField: TFieldsKey[] = [
  'store_name',
  'brand_name',
  'category',
  'subcategory',
  'product_name',
  'product_description',
  'variant_name', // => each product will have at least 1 variant
  'weight',
  'weight_unit',
  'inventory',
  'price',
  'main_image',
  'image_1',
];

export const fieldMustTrim: TFieldsKey[] = [
  'store_name',
  'brand_name',
  'category',
  'subcategory',
  'product_name',
  'variant_name',
];

export const fieldMustBeNumeric: TFieldsKey[] = [
  'weight',
  'length',
  'width',
  'height',
  'inventory',
  'inventory_low_level',
  'original_price',
  'price',
];

// ------------------------------ Helpers -------------------------- //

type GenerateFieldsFunc = (
  t: TFunction,
  data: TFieldData[number],
) => Fields<TFieldsKey>;

export const generateFields: GenerateFieldsFunc = (t, data) => {
  const validations = [];

  // require field
  if (requireField.includes(data[1])) {
    validations.push({
      rule: 'required',
      errorMessage: t('form.invalid.required', { fieldName: data[0] }),
      level: 'error',
    });
  }

  return {
    label: data[0],
    key: data[1],
    alternateMatches: [data[0]],
    fieldType: { type: 'input' },
    example: data[2],
    validations,
  };
};

type ValidateFieldsFunc = (
  validator: (arg: string, ...args: string[]) => boolean,
) => (
  value: string | undefined | boolean,
  onError: () => void,
  ...otherValues: string[]
) => void;

export const validateFields: ValidateFieldsFunc = (validator) => {
  return (value, onError, ...otherValues): void => {
    if (!value || typeof value !== 'string') return;
    if (validator(value, ...otherValues)) onError();
  };
};

export const checkOutterSpace = validateFields(
  (fieldValue) => fieldValue.trim() !== fieldValue,
);

// Check string of number with dot (e.g. 2000.00)
export const isStringNumeric = (input: string) => /^\d+(\.\d+)?$/.test(input);

export const checkStringNumeric = validateFields(
  (str) => !isStringNumeric(str),
);

export const checkSecondGreaterThanFirst = validateFields((a, b) =>
  !isStringNumeric(a) || !isStringNumeric(b) ? false : Number(a) < Number(b),
);

export const checkNumberGreaterThanZero = validateFields((input) =>
  !isStringNumeric(input) ? false : Number(input) <= 0,
);

export const checkWeightUnit = validateFields(
  (unit) => !['lb', 'oz'].includes(unit),
);

export const checkDimensionUnit = validateFields(
  (unit) => !['in', 'fit'].includes(unit),
);

export const checkImage = validateFields((image) => !image.startsWith('http'));

// Create a function to transform the data structure
export const transformErrorResponseToExcelType = (
  input: Record<string, string[]>,
): Array<Record<string, string>> => {
  const keys = keysFromObject(input);
  const maxLength = Math.max(...keys.map((key) => input[key].length));
  const result = [];

  for (let i = 0; i < maxLength; i++) {
    const row: Record<string, string> = {};
    keys.forEach((key) => (row[key] = input[key][i] || ''));
    result.push(row);
  }

  return result;
};

export const parseToStringNumber = (
  currencyStr: string | boolean | undefined,
) =>
  isString(currencyStr) ? currencyStr.replace(/[^0-9.]/g, '') : currencyStr;

export const parseFloatFromString = (str: string | undefined | boolean) =>
  isString(str) ? parseFloat(str) : str;
