import { Button, Text } from '@mantine/core';
import { ArrangeModels } from 'types';
import { useUpdateAdminBanner } from 'apis/BannerApis';
import { useArrangeItems } from 'apis/CommonApi';
import IconFA from 'components/common/IconFA';
import NailzyImage from 'components/common/NailzyImage';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import DragDropList from 'pages/products/add-product/components/DragDropList';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: any[];
};
const ArrangeModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose, data = [] } = props;
  const [items, setItems] = useState([...(data || [])]);
  const { mutate: update, isPending: updating } = useArrangeItems(
    ArrangeModels.BANNER,
  );
  // ### FUNCTIONs
  useEffect(() => {
    setItems((prev) => [...(data || [])]);
  }, [data]);
  const onDragListChange = (list: any[]) => {
    setItems([...list]);
  };
  const onClickSave = () => {
    const array: any = [...data]?.map((x, i) => {
      return {
        _id: items?.[i]?._id,
        // name: items?.[i]?.name,
        position: x?.position,
      };
    });
    update(array, {
      onSuccess: () => {
        onClose();
      },
    });
  };
  // ### RENDERs
  const renderList = () => {
    return (
      <DragDropList
        containerClassName={'mb-0'}
        data={[...(items || [])]}
        onChange={(list: any) => onDragListChange(list)}
        renderItem={(provided: any, item: any, index: any) => {
          return (
            <div
              key={item?._id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="flex flex-row items-center mb-3 p-3 py-2 border rounded-3 bg-white"
            >
              <NailzyImage
                className={'border rounded'}
                style={{ width: 130, height: 50 }}
                image_url={item?.image_url}
              />
              <Text className="flex-1 px-2">
                {item?.name || `${t('products.variant')} #${index + 1}`}
              </Text>
              <IconFA icon={'grip-dots-vertical'} />
            </div>
          );
        }}
      />
    );
  };
  return (
    // <Modal
    //   size={'lg'}
    //   onClose={onClose}
    //   opened={opened}
    //   title={t('promotions.arrange_banners')}
    // >
    //   <div></div>
    //   <SectionFooter>
    //     <Button loading={updating} onClick={onClickSave}>
    //       {t('general.save')}
    //     </Button>
    //     <Button onClick={() => onClose()} variant="default">
    //       {t('general.cancel')}
    //     </Button>
    //   </SectionFooter>
    // </Modal>
    <Modal size={'lg'} onHide={onClose} show={opened}>
      <Modal.Header closeButton>
        <Text>{t('promotions.arrange_banners')}</Text>
      </Modal.Header>
      <div className="p-4">
        {renderList()}

        <SectionFooter>
          <Button loading={updating} onClick={onClickSave}>
            {t('general.save')}
          </Button>
          <Button onClick={() => onClose()} variant="default">
            {t('general.cancel')}
          </Button>
        </SectionFooter>
      </div>
    </Modal>
  );
};
export default ArrangeModal;
