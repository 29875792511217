import * as XLSX from 'xlsx';
/**
 * ExcelJS class to create and manage Excel workbooks and worksheets.
 * @template T - The type of data to be exported.
 * @param sheetName - The name of the worksheet.
 * @param data - The data to be exported follow `sheet_add_json` data structure.
 * @param header - The header row for the worksheet.
 * @exampl
 * const excelJS = new ExcelJS('Sheet1', data, ['Name', 'Age', 'City']);
 */

export class ExcelJS<T> {
  private readonly _XLSX = XLSX;
  public readonly _workbook;

  constructor() {
    this._workbook = this._XLSX.utils.book_new();
  }

  /**
   * Add a new worksheet to the workbook using AOA to adding header and JSON to adding data
   * @param sheetName
   * @param data
   * @param header
   */
  addSheet(sheetName: string, data: T[], header?: string[]) {
    const worksheet = this._XLSX.utils.sheet_new();

    if (header) this._XLSX.utils.sheet_add_aoa(worksheet, [header]);

    this._XLSX.utils.sheet_add_json(worksheet, data, {
      skipHeader: true,
      origin: header ? 'A2' : 'A1',
    });

    this._XLSX.utils.book_append_sheet(this._workbook, worksheet, sheetName);
  }

  getBuffer() {
    return this._XLSX.write(this._workbook, {
      bookType: 'xlsx',
      type: 'buffer',
    });
  }

  createFile(fileName: string) {
    const fileBuffer = this.getBuffer();

    return new File([fileBuffer], fileName, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  writeFile(fileName: string) {
    this._XLSX.writeFile(this.workbook, fileName);
  }

  get workbook() {
    return this._workbook;
  }
}
