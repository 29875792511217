import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentStatus from 'components/common/PaymentStatus';
import { currencyFormatter } from 'helpers/utils';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { Alert, Flex, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';

const getBrandIcon = (brand: string) => {
  switch (brand) {
    case 'visa':
      return 'cc-visa';
    case 'mastercard':
      return 'cc-mastercard';
    case 'amex':
      return 'cc-amex';
    case 'discover':
      return 'cc-discover';
    default:
      return 'cc-stripe';
  }
};

const PaymentMethod = React.memo(({ data }: any) => {
  const { t } = useTranslation();
  const paymentStatus = data?.payment_status;
  const paymentInformation = data?.payment_information;
  const last4 = paymentInformation?.last4 || '';
  // const expireDate = paymentInformation
  //   ? `${
  //       paymentInformation?.exp_month > 9
  //         ? paymentInformation?.exp_month
  //         : `0${paymentInformation?.exp_month}`
  //     }/${paymentInformation?.exp_year}`
  //   : '';
  const { line1, line2, state, city, country, postal_code } =
    paymentInformation?.billing_details?.address || {};
  const location = [line1, line2, state, city, postal_code, country]
    ?.filter((x) => !!x)
    ?.join(', ');
  const riskOutcome = paymentInformation?.outcome;
  const isRisked =
    riskOutcome?.risk_score >= 65 || riskOutcome?.risk_level === 'highest';
  const refundedAmount =
    paymentInformation?.refunded_amount > 0 &&
    currencyFormatter.format(paymentInformation?.refunded_amount);

  return (
    <SectionWrapper>
      <SectionHeader title={t('orders.payment_information')} isSubSection />
      <SectionBody>
        <div>
          <PaymentStatus status={paymentStatus} />
          <Text size="" c={'red'}>
            {refundedAmount}
          </Text>
        </div>
        {paymentInformation && (
          <>
            <Flex className="mt-2" align={'center'} gap={'xs'}>
              <IconFA
                type="brands"
                icon={`${getBrandIcon(paymentInformation?.brand)}`}
              />
              <Text>{last4 ? `*${last4}` : ''}</Text>
            </Flex>
            <Text>
              {t('orders.billing_address')}: {location}
            </Text>
            {t('orders.fraud_analysis')}:
            <Alert
              icon={
                <IconFA
                  icon={isRisked ? 'triangle-exclamation' : 'shield-check'}
                />
              }
              color={isRisked ? 'red' : 'green'}
              className="my-2"
              title={isRisked ? t('orders.high_risk') : t('orders.normal_risk')}
            >
              {riskOutcome?.seller_message || riskOutcome?.risk_level}
            </Alert>
          </>
        )}
      </SectionBody>
    </SectionWrapper>
  );
});

export default PaymentMethod;
