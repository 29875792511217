import {
  FacebookAuthProvider,
  linkWithPopup,
  signInWithPopup,
  unlink
} from 'firebase/auth';

import { auth } from 'firebase-app';

const provider = new FacebookAuthProvider();

export const useSignInFacebook = () => {
  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // const credential = FacebookAuthProvider.credentialFromResult(result);
      return result.user;
    } catch (error) {
      console.error('Facebook sign-in failed:', error);
    }
  };

  const linkAccount = async () => {
    try {
      await linkWithPopup(auth.currentUser, provider);
    } catch (error) {
      console.error('Facebook link failed:', error);
      throw error;
    }
  };

  const unlinkAccount = async () => {
    try {
      await unlink(auth.currentUser, 'facebook.com');
      auth.currentUser.getIdToken(true);
    } catch (error) {
      console.error('Facebook unlink failed:', error);
    }
  };

  return {
    signIn,
    linkAccount,
    unlinkAccount
  };
};
