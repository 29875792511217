import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

export const Loading = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ height: '80vh' }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="4x" className="text-300" />
    </Flex>
  );
};
