import { StatusEnum } from 'types';
import RenderOptionWithStatus from 'components/common/inputs/RenderOptionWithStatus';
import { t } from 'i18next';
import { InputError } from '@mantine/core';
import { useAuth } from 'contexts/AuthContext';
import { checkPermission } from 'helpers/utils';
import SingleSelect, { SelectItem, SingleSelectProps } from './SingleSelect';
import MultiSelect from './MultiSelect';

type PropType =
  | {
      multiple?: false;
      value: SelectItem;
      onChange: (id: SelectItem) => void;
    }
  | {
      multiple: true;
      value: SelectItem[];
      onChange: (ids: SelectItem[]) => void;
    };

const SellerStoreSelect = (props: SingleSelectProps & PropType) => {
  // ### CONSTANTse
  const { value, onChange, multiple, ...els } = props;
  // sellers
  const { user } = useAuth();
  const isGranted = checkPermission(user, '/customers');
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps: SingleSelectProps = {
    route: '/admin/sellers',
    label: t('products.store'),
    placeholder: t('general.select'),
    searchField: 'store_name',
    disabled: !isGranted,
    renderOption: (option: any, isSelected?: boolean) => (
      <RenderOptionWithStatus {...option} isSelected={isSelected} />
    ),
    //
    optionTransformer: (data: any) =>
      data?.results?.map((x: any) => ({
        label: x.store_name,
        value: x._id,
        status: x.status,
      })),
    ...els,
  };
  if (multiple) {
    return (
      <MultiSelect
        {...generalProps}
        selected={value}
        onChange={(value) => {
          onChange?.(value as SelectItem[]);
        }}
      />
    );
  } else {
    return (
      <SingleSelect
        {...generalProps}
        selected={value}
        onChange={(value) => {
          onChange?.(value as SelectItem);
        }}
      />
    );
  }
};
export default SellerStoreSelect;
