import { Button, Grid, Modal, TextInput } from '@mantine/core';
import { t } from 'i18next';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useCreateCustomer, useUpdateCustomer } from 'helpers/mutation-utils';
import useRequestImage from 'hooks/file/useGenerateImageId';
import { imageUrlToId } from 'pages/dashboard/components';
import { syncImagePaths } from 'apis/ImagesApis';
import { userKeys } from '../queryKeys';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import RadioGroupInput from 'components/MantineUI/Inputs/RadioGroupInput';
import { getCustomerTitle } from '..';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import PhoneInput from 'components/MantineUI/Inputs/PhoneInput';
import validator from 'validator';
import * as yup from 'yup';
import {
  EMAIL_INVALID_ERROR_MESSAGE,
  PHONE_NO_REQUIRE_ERROR_MESSAGE,
} from 'constants/constants';
import CommonAvatar from 'components/MantineUI/CommonAvatar';
import UploadWrapper from 'components/MantineUI/UploadWrapper';
type PropType = {
  opened: boolean;
  onClose: () => void;
  value?: any;
};
const CustomerDetailsModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose: closeProps, value } = props;
  const isEdit = !!value?._id;
  const schema = yup.object({
    first_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.first_name'),
      }),
    ),
    last_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.last_name'),
      }),
    ),
    email: yup
      .string()
      .email(EMAIL_INVALID_ERROR_MESSAGE)
      .when(['phone_number'], ([phone_number], schema) => {
        return !isEdit && phone_number?.length < 3
          ? schema.required(
              t('form.invalid.required', {
                fieldName: t('register.form.email'),
              }),
            )
          : isEdit && value?.email
          ? schema.required(
              t('form.invalid.required', {
                fieldName: t('register.form.email'),
              }),
            )
          : schema;
      }),
    phone_number: yup
      .string()
      .test({
        name: 'is-phone-number',
        test(value: any) {
          return (
            !value ||
            value?.length < 4 ||
            validator.isMobilePhone(value || '', undefined, {
              strictMode: false,
            })
          );
        },
      })
      .nullable(),
    business_phone: yup
      .string()
      .test({
        name: 'is-phone-number',
        test(value: any) {
          return (
            !value ||
            value?.length < 4 ||
            validator.isMobilePhone(value, undefined, {
              strictMode: false,
            })
          );
        },
        message: t('form.invalid.invalid', {
          fieldName: t('register.form.phone'),
        }),
      })
      .nullable(),
    status: yup.string(),
  });
  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {} as any,
    validate: yupResolver(schema),
  });
  useEffect(() => {
    setValues({
      first_name: value?.first_name,
      last_name: value?.last_name,
      profile_image_url: value?.profile_image_url,
      status: value?.status || 'active',
      title_type: value?.title_type || 'nail_tech',
      email: value?.email,
      business_name: value?.business_name,
      phone_number: value?.phone_number || undefined,
      business_phone: value?.business_phone || undefined,
    });
  }, [value]);
  const {
    reset,
    onSubmit,
    setValues,
    getInputProps,
    errors,
    isDirty,
    getValues,
  } = form;
  const values = getValues();
  const [saving, setSaving] = useState(false);
  const { createImgID } = useRequestImage();
  const { mutate: createCustomer, isPending: isCreating } = useCreateCustomer();
  const { mutate: updateCustomer, isPending: isUpdating } = useUpdateCustomer();

  // ### FUNCTIONs
  const onSave = async (inputData: any) => {
    setSaving(true);
    const {
      _id,
      first_name,
      last_name,
      phone_number,
      business_name,
      title_type,
      profile_image_url,
      email,
      status,
      logo,
      business_phone,
    } = inputData;

    let submitData: any = {
      id: value?._id,
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
      phone_number:
        phone_number?.trim()?.length < 8 ? null : phone_number?.trim(),
      business_name: business_name?.trim() || (isEdit ? null : undefined),
      email: email?.trim() || undefined,
      business_phone:
        business_phone?.trim()?.length < 8
          ? isEdit
            ? null
            : undefined
          : business_phone?.trim(),
      title_type,
      profile_image_id:
        imageUrlToId(profile_image_url) || isEdit ? null : undefined,
      status,
    };

    if (logo?.src) {
      const image_url = await createImgID(logo, 'customers', false, {
        maxWidth: 500,
        maxHeight: 500,
      });
      submitData = {
        ...submitData,
        profile_image_id: imageUrlToId(image_url),
      };
      syncImagePaths([image_url]);
    }
    if (isEdit) {
      updateCustomer(submitData, {
        onSuccess: () => {
          queryClient.invalidateQueries(
            userKeys.lists() as InvalidateQueryFilters,
          );
          reset();
          setSaving(false);
          toast.success(t('general.update_success'));
          onClose();
        },
        onError: (err: any) => {
          toast.error(err?.description?.message || t('general.update_fail'));
          setSaving(false);
        },
      });
    } else {
      createCustomer(submitData, {
        onSuccess: (res) => {
          queryClient.invalidateQueries(
            userKeys.lists() as InvalidateQueryFilters,
          );
          reset();
          //   handleResetState();
          setSaving(false);
          // responseData(res?.data);
          toast.success(t('general.create_success'));
          onClose();
        },
        onError: (err: any) => {
          toast.error(err?.description?.message || t('general.create_fail'));
          setSaving(false);
        },
      });
    }
  };
  const onClose = () => {
    reset();
    closeProps?.();
  };

  const isLoading = isCreating || isUpdating;
  // ### RENDERs
  return (
    <Modal
      size={'xl'}
      onClose={onClose}
      opened={opened}
      title={
        isEdit ? t('customer.edit_customer') : t('customer.create_customer')
      }
    >
      <form onSubmit={onSubmit(onSave)}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 12 }}>
            <UploadWrapper
              resize={{ width: 1200, height: 1200 }}
              className="w-fit cursor-pointer"
              onFileChange={(files: any) =>
                form.setValues({ logo: files?.[0] })
              }
            >
              <CommonAvatar
                size={'xl'}
                radius={90}
                user={values}
                src={(values?.logo && values?.logo?.src) || values?.logo_url}
              />
            </UploadWrapper>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              required
              label={t('register.form.first_name')}
              placeholder="John"
              {...getInputProps('first_name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              required
              label={t('register.form.last_name')}
              placeholder="Doe"
              {...getInputProps('last_name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              required={
                (!isEdit && values.phone_number?.length < 3) ||
                (isEdit && value?.email)
              }
              label={t('register.form.email')}
              placeholder="abc123@gmail.com"
              {...getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <PhoneInput
              required={!values.email}
              label={t('register.form.mobile')}
              placeholder="+1 (432) 432-4324"
              defaultValue={value?.phone_number || undefined}
              onChangeText={(phone_number: any) =>
                setValues({ phone_number: phone_number })
              }
              error={errors?.phone_number}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              label={t('customer.business_name')}
              placeholder="ABC company"
              {...getInputProps('business_name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <PhoneInput
              label={t('customer.business_phone')}
              placeholder="+1 (432) 432-4324"
              defaultValue={value?.business_phone || undefined}
              error={errors?.business_phone}
              onChangeText={(phone_number: any) =>
                setValues({ business_phone: phone_number })
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <StatusSelect {...getInputProps('status')} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12 }}>
            <RadioGroupInput
              value={values?.title_type}
              onChange={(value) => setValues({ title_type: value })}
              label={t('customer.role_type')}
              data={[
                { label: getCustomerTitle('nail_tech'), value: 'nail_tech' },
                {
                  label: getCustomerTitle('salon_owner'),
                  value: 'salon_owner',
                },
                {
                  label: getCustomerTitle('nail_supply'),
                  value: 'nail_supply',
                },
                { label: getCustomerTitle('other'), value: 'other' },
              ]}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, sm: 12 }}>
            <SectionFooter updatedTime={value?.updated_on}>
              <Button loading={saving || isLoading} type="submit">
                {t('general.save')}
              </Button>
              <Button onClick={() => onClose()} variant="default">
                {t('general.cancel')}
              </Button>
            </SectionFooter>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
export default CustomerDetailsModal;
