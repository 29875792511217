import React from 'react';
import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Popover } from 'react-bootstrap';
import SoftBadge from './SoftBadge';
import IconFA from 'components/common/IconFA';
import { getColor } from 'helpers/utils';
import { isNumeric } from 'validator';

const DashboardStatItem = ({
  label,
  current = '',
  previous = '',
  note,
  format,
}) => {
  return (
    <>
      <div className="fw-normal text-700 fs--1">
        {label}
        <OverlayTrigger
          placement="right"
          overlay={
            <Popover id="weightTooltip">
              <Popover.Body>{note}</Popover.Body>
            </Popover>
          }
        >
          <span className="ms-1 fs--1 text-secondary">
            <IconFA
              className={' fs--1 ms-1'}
              icon="circle-question"
              color={'#999'}
            />
          </span>
        </OverlayTrigger>
      </div>
      <div className="my-1 fs-1 text-900 fw-medium">
        <span>
          {isNumeric(current.toString())
            ? format
              ? format(current)
              : current
            : '--'}
        </span>
      </div>
      <span className="fs--1 text-900 fw-normal">
        {isNumeric(previous.toString())
          ? format
            ? format(previous)
            : previous
          : '--'}
        {!isNumeric(previous.toString()) ||
        !isNumeric(current.toString()) ||
        current === previous ? null : (
          <SoftBadge
            className="ms-2 fs--2"
            bg={previous <= current ? 'success' : 'danger'}
            pill
          >
            <IconFA
              icon={`caret-${previous <= current ? 'up' : 'down'}`}
              color={getColor(previous <= current ? 'success' : 'danger')}
            />

            <span className="ms-1">
              {current === previous
                ? 0
                : current < 0
                ? 100
                : parseFloat(
                    (
                      (Math.abs((previous || 0) - current) /
                        (previous || current)) *
                      100
                    ).toFixed(0),
                  )}
              %
            </span>
          </SoftBadge>
        )}
      </span>
    </>
  );
};

export default DashboardStatItem;
