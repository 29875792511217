import {
  Avatar,
  AvatarProps,
  MantineRadius,
  MantineSize,
  MantineStyleProp,
  Tooltip,
} from '@mantine/core';
import { UserInfo } from 'types';
type PropType = AvatarProps & {
  className?: string;
  user?: UserInfo;
  src?: string;
  size?: MantineSize | number;
  radius?: MantineRadius;
  onChange?: () => void;
  showNameTooltip?: boolean;
};
const CommonAvatar = (props: PropType) => {
  // ### CONSTANTs
  const {
    className = '',
    user,
    src,
    size = 'md',
    radius = 'xl',
    showNameTooltip = false,
  } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Tooltip
      multiline
      maw={300}
      disabled={!showNameTooltip}
      label={
        user?.full_name ||
        `${user?.first_name || '-'} ${user?.last_name || '-'}`
      }
    >
      <Avatar
        color="primary"
        radius={radius}
        size={size}
        src={src || user?.profile_image_url}
        className={`${className} border`}
        name={`${user?.first_name || ''} ${user?.last_name || ''}`}
      />
    </Tooltip>
  );
};
export default CommonAvatar;
