import {
  OAuthProvider,
  linkWithPopup,
  signInWithPopup,
  unlink
} from 'firebase/auth';

import { auth } from 'firebase-app';

const provider = new OAuthProvider('apple.com');

export const useSignInWithApple = () => {
  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      return result.user;
    } catch (error) {
      console.error('Apple sign-in failed:', error);
    }
  };

  const linkAccount = async () => {
    try {
      await linkWithPopup(auth.currentUser, provider);
    } catch (error) {
      console.error('Apple link failed:', error);
      throw error;
    }
  };

  const unlinkAccount = async () => {
    try {
      await unlink(auth.currentUser, 'apple.com');
      auth.currentUser?.getIdToken(true);
    } catch (error) {
      console.error('Apple unlink failed:', error);
    }
  };

  return {
    signIn,
    linkAccount,
    unlinkAccount
  };
};
