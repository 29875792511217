import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import saleRepCartDetail from './saleRepCartDetailReducer';
import saleRepCart from './saleRepCartReducer';
import cart from './cartReducer';
import order from './orderReducer';
import orderDetail from './orderDetailReducer';
import payment from './paymentReducer';
import dashboard from './dashboardChartReducer';
import product from './productReducer';
import image from './imagesReducers';

export default combineReducers({
  saleRepCartDetail,
  saleRepCart,
  cart,
  order,
  orderDetail,
  payment,
  product,
  dashboard,
  auth,
  message,
  image
});
