import StatusBadge from 'components/common/StatusBadge';

export const FormatSelectOptionLabelWithStatus = ({ label, status }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <p>{label}</p>
      {status && status !== 'active' && (
        <StatusBadge status={status} withIcon={false} />
      )}
    </div>
  );
};

export default FormatSelectOptionLabelWithStatus;
