import { Text } from '@mantine/core';
import { dateTimeFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
  className?: string;
  updatedTime?: number;
};
const SectionFooter = (props: PropType) => {
  // ### CONSTANTs
  const { children, className, updatedTime } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div
      className={`flex flex-row-reverse w-full gap-2 items-end ${className}`}
    >
      {children}
      {updatedTime ? (
        <Text size="xs" flex={1}>{`${t(
          'general.updated_date',
        )}: ${dateTimeFormatter(updatedTime)}`}</Text>
      ) : null}
    </div>
  );
};
export default SectionFooter;
