import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button } from '@mantine/core';
import { CommonTableColumn, RoleItem, SystemUserItem } from 'types';
import IconFA from 'components/common/IconFA';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable } from 'components/MantineUI/CommonTable';
import PageLayout from 'components/MantineUI/PageLayout';
import { t } from 'i18next';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'constants/format';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import RoleDetailsModal from './components/RoleDetailsModal';
import { useDeletePermission, usePermissionList } from 'apis/UserApis';
import { usePaginationParams } from 'hooks/usePaginationParams';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import GroupAvatar from './components/GroupAvatar';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';
const SystemUserPermission = () => {
  // ### CONSTANTs
  const [selectedRole, setSelectedRole] = useState<RoleItem>();
  const [deleteRole, setDeleteRole] = useState<RoleItem>();
  // ### FUNCTIONs
  const { page, limit, onPaginationChange } = usePaginationParams();
  const { mutate: deleteRoleApi, isPending: deleting } = useDeletePermission();
  const { data, isLoading, isRefetching } = usePermissionList({ page, limit });
  const total = data?.data?.total || 0;
  const onDeleteRole = () => {
    deleteRoleApi(deleteRole?._id, {
      onSuccess: () => {
        setDeleteRole(undefined);
      },
      onError: () => {
        setDeleteRole(undefined);
      },
    });
  };
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('general.name'),
      Cell: (row) => (
        <Anchor size="sm" onClick={() => setSelectedRole(row)}>
          {row.name}
        </Anchor>
      ),
    },
    {
      accessor: 'permissions',
      Header: t('system_users.permissions'),
      Cell: (row: RoleItem) =>
        `${row.total_permissions} ${t('general.selected')}`,
    },
    {
      accessor: 'system_users',
      Header: t('system_users.system_users'),
      Cell: (row: RoleItem) =>
        row?.system_users?.length ? (
          <GroupAvatar
            data={row?.system_users?.map((x) => ({
              src: x?.profile_image_url || '',
              name: x?.full_name || '',
              id: x?._id || '',
            }))}
            total={row?.total_system_users || 0}
          />
        ) : (
          '--'
        ),
    },
    {
      accessor: 'created_on',
      Header: t('general.created_date'),
      Cell: (row: RoleItem) =>
        row.created_on &&
        dayjs.unix(row.created_on).format(DATETIME_FORMAT.MMMDDYYYY),
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: RoleItem) =>
        row.status && <StatusBadge status={row.status} />,
    },
    {
      accessor: 'actions',
      Cell: (row: RoleItem) => (
        <DropdownButton
          button={
            <Button variant="subtle" color="gray" size="xs">
              <IconFA icon={'ellipsis'} />
            </Button>
          }
          dropdownData={[
            {
              key: 'edit',
              label: t('general.edit'),
              onClick: () => setSelectedRole(row),
            },
            { isDivider: true },
            {
              key: 'delete',
              label: t('general.delete'),
              color: 'red',
              onClick: () => setDeleteRole(row),
            },
          ]}
        />
      ),
    },
  ];
  // ### RENDERs
  return (
    <SkeletonWrapper show={isLoading} skeleton={<ListPageSkeleton />}>
      <PageLayout
        title={t('system_users.roles')}
        breadCrumds={[
          { title: t('system_users.system_users'), href: '/system-users' },
          { title: t('system_users.roles') },
        ]}
      >
        <SectionWrapper>
          <SectionHeader
            title={t('system_users.roles')}
            subtitle={t('system_users.roles_decs')}
            actionButtons={
              <Button
                onClick={() => setSelectedRole({})}
                leftSection={<IconFA icon={faPlus} />}
              >
                {t('system_users.add_role')}
              </Button>
            }
          ></SectionHeader>
          <SectionBody>
            <LoadingOverlayWrapper loading={isRefetching}>
              <CommonTable
                data={data?.data?.results || []}
                columns={columns}
                pagination={{ page, limit, total, onPaginationChange }}
              />
            </LoadingOverlayWrapper>
          </SectionBody>
        </SectionWrapper>
        <RoleDetailsModal
          opened={!!selectedRole}
          onClose={() => setSelectedRole(undefined)}
          data={selectedRole}
        />
        <DeleteWarningModal
          name={deleteRole?.name}
          onShow={!!deleteRole}
          onClose={() => setDeleteRole(undefined)}
          onDelete={onDeleteRole}
          isLoading={deleting}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default SystemUserPermission;
