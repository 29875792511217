import {
  EMAIL_INVALID_ERROR_MESSAGE,
  PASSWORD_PATTERN,
  PASSWORD_PATTERN_ERROR_MESSAGE,
  PASSWORD_INVALID_ERROR_MESSAGE,
  CONFIRM_PASSWORD_ERROR_MESSAGE,
} from 'constants/constants';
import { t } from 'i18next';
import * as yup from 'yup';

export const getUserValidation = () => {
  const userValidationSchema = yup.object({
    first_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.first_name'),
      }),
    ),
    last_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.last_name'),
      }),
    ),
    email: yup
      .string()
      .email(EMAIL_INVALID_ERROR_MESSAGE)
      .required(
        t('form.invalid.required', {
          fieldName: t('register.form.email'),
        }),
      ),
    status: yup.string(),
  });

  return userValidationSchema;
};

export const getChangePasswordValidation = () => {
  const changePasswordValidationSchema = yup.object({
    password: yup
      .string()
      .matches(PASSWORD_PATTERN, PASSWORD_PATTERN_ERROR_MESSAGE)
      .required(PASSWORD_INVALID_ERROR_MESSAGE),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], CONFIRM_PASSWORD_ERROR_MESSAGE)
      .required(PASSWORD_INVALID_ERROR_MESSAGE),
  });

  return changePasswordValidationSchema;
};
