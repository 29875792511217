import { Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { STATUS_INFO, StatusEnum } from 'types';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';

type Props = {
  status: StatusEnum;
  className?: string;
  fullWidth?: boolean;
};

const StatusBadge = ({ status, className, fullWidth = false }: Props) => {
  const statusIcon = STATUS_INFO[status]?.icon ?? undefined;
  const color = STATUS_INFO[status]?.color ?? 'light';
  const label = t(STATUS_INFO[status]?.label) ?? 'N/A';
  return (
    <Badge
      fullWidth={fullWidth}
      className={className}
      size="xs"
      variant="light"
      color={color}
    >
      {label}
      {statusIcon && <IconFA className={`ms-1`} icon={statusIcon} />}
    </Badge>
  );
};

export default StatusBadge;
