import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
// import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';
import { currencyFormatter } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DashboardStatItem from 'components/common/DashboardStatItem';
import { useAuth } from 'contexts/AuthContext';
import { useDashboardReport } from 'hooks/dashboard/dashboard';
import { APP_ROLES } from 'constants/constants';

export const GreetingCard = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: todayData } = useDashboardReport({
    from: dayjs().startOf('day').unix(),
    to: dayjs().set('second', 0).unix(),
  });
  const { data: yesterdayData } = useDashboardReport({
    from: dayjs().add(-1, 'day').startOf('day').unix(),
    to: dayjs().add(-1, 'day').endOf('day').unix(),
  });
  const today = todayData?.data || {};
  const yesterday = yesterdayData?.data || {};

  const storeSummary = [
    user?.type === APP_ROLES.seller
      ? {
          label: t('dashboard.store_visits'),
          previous: yesterday?.total_store_visit || 0,
          current: today?.total_store_visit || 0,
          note: t('dashboard.store_visits_decs'),
        }
      : {
          label: t('dashboard.total_customers'),
          previous: yesterday?.total_customer || 0,
          current: today?.total_customer || 0,
          note: t('dashboard.total_customers_desc'),
        },
    {
      label: t('dashboard.product_views'),
      previous: yesterday?.total_product_view || 0,
      current: today?.total_product_view || 0,
      note: t('dashboard.product_views_decs'),
    },
    {
      label: t('dashboard.items_sold'),
      previous: yesterday?.total_item_sold || 0,
      current: today?.total_item_sold || 0,
      note: t('dashboard.items_sold_decs'),
    },
    {
      label: t('dashboard.total_paid_orders'),
      previous: yesterday?.total_order || 0,
      current: today?.total_order || 0,
      note: t('dashboard.total_paid_orders_desc'),
    },
    {
      label: t('dashboard.total_paid_sales'),
      previous: yesterday?.total_sale || 0,
      current: today?.total_sale || 0,
      format: (text) => currencyFormatter.format(text),
      note: t('dashboard.total_paid_sales_desc'),
    },
    {
      label: t('dashboard.conversion_rate'),
      previous: yesterday?.conversion_rate || 0,
      current: today?.conversion_rate || 0,
      format: (text) => `${text}%`,
      note: t('dashboard.conversion_rate_decs'),
    },
    {
      label: t('dashboard.total_unpaid_orders'),
      previous: yesterday?.total_unpaid_order || 0,
      current: today?.total_unpaid_order || 0,
      note: t('dashboard.total_unpaid_orders_desc'),
    },
    {
      label: t('dashboard.total_unpaid_sales'),
      previous: yesterday?.total_unpaid_sale || 0,
      current: today?.total_unpaid_sale || 0,
      format: (text) => currencyFormatter.format(text),
      note: t('dashboard.total_unpaid_sales_desc'),
    },
    {
      isEmpty: true,
    },
    {
      label: t('dashboard.total_buyer'),
      previous: yesterday?.total_buyer || 0,
      current: today?.total_buyer || 0,
      format: (text) => text,
      note: t('dashboard.total_buyer_desc'),
    },
    {
      label: t('dashboard.total_returning_buyer'),
      previous: yesterday?.total_returning_buyer || 0,
      current: today?.total_returning_buyer || 0,
      format: (text) => text,
      note: t('dashboard.total_returning_buyer_desc'),
    },
    {
      label: t('dashboard.total_potential_buyer'),
      previous: yesterday?.total_potential_buyer || 0,
      current: today?.total_potential_buyer || 0,
      format: (text) => text,
      note: t('dashboard.total_potential_buyer_desc'),
    },
  ];

  return (
    <Card className="mb-3 p-2 overflow-hidden">
      <Card.Body className="position-relative">
        <div className="position-relative z-index-2">
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div>
              <h3 className="text-primary mb-1">
                {`${t('dashboard.welcome')}, `}
                {user?.first_name} 👋
              </h3>
              <p className="fs--1 text-700">{t('dashboard.greeting')}</p>
            </div>
            <p className="fs--2 text-700">
              {dayjs().format('dddd, MMMM DD YYYY [at] hh:mm A')}
            </p>
          </div>

          <Row className="mb-3">
            {storeSummary?.map((data, key) => {
              return (
                <Col className="mt-4" key={data} xs={6} lg={4}>
                  {data?.isEmpty ? null : <DashboardStatItem {...data} />}
                </Col>
              );
            })}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

GreetingCard.propTypes = {
  user: PropTypes.object,
};
