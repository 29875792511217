import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { Button, Flex, Text, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteCustomer, useSendEmailResetPass } from 'apis/CustomersApi';
import IconFA from 'components/common/IconFA';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import CommonAvatar from 'components/MantineUI/CommonAvatar';
import {
  SectionBody,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { dateTimeFormatter, relativeTimestamp } from 'helpers/utils';
import { t } from 'i18next';
import CustomerDetailsModal from 'pages/customers/components/CustomerDetailsModal';
import DeleteCustomerModal from 'pages/customers/components/DeleteCustomerModal';
import { userKeys } from 'pages/customers/queryKeys';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
type PropType = {
  customer?: any;
};
const CustomerDetailsCard = (props: PropType) => {
  // ### CONSTANTs
  const { customer } = props;
  const { first_name, last_name, created_on, updated_on, status } =
    customer || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [deleteCustomer, setDeleteCustomer] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const { mutate: onDeleteCustomer, isPending: deletingCustomer }: any =
    useDeleteCustomer();
  const { mutate: sendEmail, isPending: sendingEmail } =
    useSendEmailResetPass();
  // ### FUNCTIONs
  const handleChangePwDropdownBtn = () => {
    sendEmail(customer?._id, {
      onSuccess: (res: any) => {
        if (res?.status) {
          toast.success(t('customer.send_email_reset_pass_success'));
        }
      },
      onError: (res) => {
        toast.error(t('customer.send_email_reset_pass_fail'));
      },
    });
  };
  const onDelete = (data: any, reason: string) => {
    onDeleteCustomer(
      { id: deleteCustomer?._id, reason },
      {
        onSuccess: () => {
          toast.success(t('general.delete_success'));
          setDeleteCustomer(undefined);
          navigate('/customers');
          queryClient.invalidateQueries(userKeys.lists() as any);
        },
      },
    );
  };
  // ### RENDERs
  return (
    <SectionWrapper>
      <SectionBody>
        <Flex>
          <CommonAvatar user={customer} size={'xl'} radius={100} />
          <div className="flex-1 ms-3">
            <Flex>
              <Title order={1} flex={1}>
                {first_name ? `${first_name} ${last_name}` : '--'}
              </Title>
              <DropdownButton
                button={
                  <Button variant="default" size="xs" color="gray">
                    <IconFA icon={faEllipsis} />
                  </Button>
                }
                dropdownData={[
                  {
                    key: 'edit_customer',
                    onClick: () => setSelectedUser(customer),
                    label: t('customer.edit_customer'),
                  },
                  {
                    key: 'send_email_reset_pass',
                    onClick: () => handleChangePwDropdownBtn(),
                    label: t('customer.send_email_reset_pass'),
                  },
                  { isDivider: true },
                  {
                    color: 'red',
                    key: 'delete_customer',
                    onClick: () => setDeleteCustomer(customer),
                    label: t('customer.delete_customer'),
                  },
                ]}
              />
            </Flex>
            <Text className="my-1" size="sm" c={'gray.7'}>
              {t('general.joined_on')} {relativeTimestamp(created_on)}
              {updated_on !== 0 && (
                <>
                  {' '}
                  &#x2022; {t('general.last_update_on')}{' '}
                  {dateTimeFormatter(updated_on)}{' '}
                </>
              )}
            </Text>
            <StatusBadge status={status} />
          </div>
        </Flex>
      </SectionBody>
      <DeleteCustomerModal
        onShow={!!deleteCustomer}
        name={
          !deleteCustomer?.first_name && !deleteCustomer?.first_name
            ? t('orders.customer').toLowerCase()
            : `${deleteCustomer?.first_name || '-'} ${
                deleteCustomer?.last_name || '-'
              }`
        }
        onClose={() => setDeleteCustomer(undefined)}
        onDelete={onDelete}
        isLoading={!!deletingCustomer}
      />
      <CustomerDetailsModal
        opened={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        value={selectedUser}
      />
    </SectionWrapper>
  );
};
export default CustomerDetailsCard;
