import { dateTimeFormatter } from 'helpers/utils';
import { t } from 'i18next';
import CartStatus from 'pages/draft-carts/component/CartStatus';
import { Button, Card } from 'react-bootstrap';

const DCInfo = ({ onSelect, data }) => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between">
          <h4>
            {t('draft_carts.draft_cart')} {data?._id ? `# ${data?._id}` : ''}
          </h4>
          <div>
            {data?.status !== 'completed' ? (
              <Button size="sm" variant="outline-primary" onClick={onSelect}>
                {t('promotions.select_products')}
              </Button>
            ) : null}
          </div>
        </div>
      </Card.Header>
      {data?._id ? (
        <Card.Body className="align-self-start d-flex flex-column pt-0 gap-2">
          <div className="fs--1">{dateTimeFormatter(data?.created_on)}</div>
          <CartStatus status={data?.status} />
          <div className="fs--1">
            {t('general.created_by')}{' '}
            <span className="fw-semi-bold">
              {`${data?.created_by?.first_name} ${data?.created_by?.last_name}`}
            </span>
          </div>
        </Card.Body>
      ) : null}
    </Card>
  );
};

export default DCInfo;
