import { Card, Col, Placeholder, Row, Table } from 'react-bootstrap';

export const PlaceholderCard = () => {
  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={12} md={3}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="py-0">
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={5} md={12}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>
          <Col xs={5} md={12}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={5} md={4}>
            <Placeholder as="h6" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>

          <Col xs={6} md={3}>
            <Row className="justify-content-end">
              <Col xs={6} md={6}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={6}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export const PlaceholderTableNoHeader = () => {
  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={12} md={8}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>

          <Col xs={12} md={3}>
            <Row className="justify-content-end">
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="py-0">
        <Table className="mb-0">
          <thead className="bg-200">
            <tr>
              <th>
                <Placeholder as="h6" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </th>
              {(() => {
                const columns = Array.from({ length: 6 }).map((_, index) => (
                  <th className="text-end" key={index}>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} md={8} />
                    </Placeholder>
                  </th>
                ));
                return columns;
              })()}
            </tr>
          </thead>

          <tbody>
            {(() => {
              const rows = Array.from({ length: 9 }).map((_, index) => (
                <tr key={index}>
                  <td>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} md={12} />
                    </Placeholder>
                  </td>
                  {(() => {
                    const columns = Array.from({ length: 6 }).map(
                      (_, index) => (
                        <td className="text-end" key={index}>
                          <Placeholder as="h6" animation="glow">
                            <Placeholder xs={6} md={8} />
                          </Placeholder>
                        </td>
                      )
                    );
                    return columns;
                  })()}
                </tr>
              ));
              return rows;
            })()}
          </tbody>
        </Table>
      </Card.Body>

      <Card.Footer>
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={5} md={4}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>

          <Col xs={6} md={3}>
            <Row className="justify-content-end">
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export const PlaceholderTable = () => {
  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center gap-3">
          <Col xs={12} md={2}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={6} md={12} />
            </Placeholder>
          </Col>

          <Col xs={12} md={4}>
            <Row className="justify-content-end">
              <Col xs={3} md={4}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
              <Col xs={3} md={4}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={4}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={12} md={8}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>

          <Col xs={12} md={3}>
            <Row className="justify-content-end">
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body className="py-0">
        <Table className="mb-0">
          <thead className="bg-200">
            <tr>
              <th>
                <Placeholder as="h6" animation="glow">
                  <Placeholder xs={6} md={12} />
                </Placeholder>
              </th>
              {(() => {
                const columns = Array.from({ length: 6 }).map((_, index) => (
                  <th className="text-end" key={index}>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} md={8} />
                    </Placeholder>
                  </th>
                ));
                return columns;
              })()}
            </tr>
          </thead>

          <tbody>
            {(() => {
              const rows = Array.from({ length: 9 }).map((_, index) => (
                <tr key={index}>
                  <td>
                    <Placeholder as="h6" animation="glow">
                      <Placeholder xs={6} md={12} />
                    </Placeholder>
                  </td>
                  {(() => {
                    const columns = Array.from({ length: 6 }).map(
                      (_, index) => (
                        <td className="text-end" key={index}>
                          <Placeholder as="h6" animation="glow">
                            <Placeholder xs={6} md={8} />
                          </Placeholder>
                        </td>
                      )
                    );
                    return columns;
                  })()}
                </tr>
              ));
              return rows;
            })()}
          </tbody>
        </Table>
      </Card.Body>

      <Card.Footer>
        <Row className="flex-between-center mt-2 gap-3">
          <Col xs={5} md={4}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} md={12} />
            </Placeholder>
          </Col>

          <Col xs={6} md={3}>
            <Row className="justify-content-end">
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
              <Col xs={6} md={5}>
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} md={12} />
                </Placeholder>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export const PlaceholderH1 = () => {
  return (
    <>
      <Placeholder as="h4" animation="glow">
        <Placeholder xs={4} md={4} />
      </Placeholder>
    </>
  );
};

export const PlaceholderStatItemNoPct = () => {
  return (
    <>
      <Placeholder as="h4" animation="glow">
        <Placeholder xs={2} md={2} />
      </Placeholder>
    </>
  );
};

export const PlaceholderStatItem = () => {
  return (
    <>
      <Placeholder as="h4" animation="glow">
        <Placeholder xs={2} md={2} />
      </Placeholder>
      <Placeholder as="h5" animation="glow">
        <Placeholder xs={4} md={3} />
      </Placeholder>
    </>
  );
};

export const PlaceholderTabTitle = () => {
  return (
    <>
      <Placeholder as="h4" animation="glow">
        <Placeholder xs={5} md={6} />
      </Placeholder>
      <Placeholder as="h5" animation="glow">
        <Placeholder xs={7} md={12} />
      </Placeholder>
    </>
  );
};

export const PlaceholderBestSelling = () => {
  return (
    <>
      <tr className="border-top border-200">
        <td>
          <Placeholder as="h1" animation="glow">
            <Placeholder xs={12} size="lg" />
          </Placeholder>
        </td>
        <td>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} md={10} />
            <br />
            <Placeholder xs={8} md={2} />
            <br />
            <Placeholder xs={6} md={2} />
          </Placeholder>
        </td>
        <td className="align-middle text-center">
          <Placeholder as="h6" animation="glow">
            <Placeholder xs={8} md={2} />
          </Placeholder>
        </td>
        <td className="align-middle text-center">
          <Placeholder as="h6" animation="glow">
            <Placeholder xs={8} md={2} />
          </Placeholder>
        </td>
        <td className="align-middle text-end">
          <Placeholder as="h6" animation="glow">
            <Placeholder xs={8} md={2} />
          </Placeholder>
        </td>
      </tr>
    </>
  );
};

export const PlaceholderDetails = () => {
  return (
    <>
      <Row className="g-3">
        <Col xs={12} lg={8} xxl={9}>
          <Card className="mb-3">
            <Card.Header>
              <Placeholder as="h3" animation="glow">
                <Placeholder xs={6} md={2} />
              </Placeholder>
              <Placeholder as="h6" animation="glow">
                <Placeholder xs={4} md={1} />
              </Placeholder>
              <Placeholder as="h6" animation="glow">
                <Placeholder xs={4} md={1} />
              </Placeholder>
            </Card.Header>
          </Card>

          {(() => {
            const cards = Array.from({ length: 3 }).map((_, index) => (
              <Card className="mb-3" key={index}>
                <Card.Header className="border-bottom border-200">
                  <Placeholder as="h5" animation="glow">
                    <Placeholder xs={10} md={6} />
                  </Placeholder>
                  <Placeholder as="h5" animation="glow">
                    <Placeholder xs={10} md={6} />
                  </Placeholder>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                </Card.Header>

                <Card.Body>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                </Card.Body>
              </Card>
            ));
            return cards;
          })()}
        </Col>

        <Col xs={12} lg={4} xxl={3}>
          {(() => {
            const cards = Array.from({ length: 4 }).map((_, index) => (
              <Card className="mb-3" key={index}>
                <Card.Header className="border-bottom border-200">
                  <Placeholder as="h5" animation="glow">
                    <Placeholder xs={6} md={6} />
                  </Placeholder>
                </Card.Header>

                <Card.Body>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                  <Placeholder as="h6" animation="glow">
                    <Placeholder xs={6} md={3} />
                  </Placeholder>
                </Card.Body>
              </Card>
            ));
            return cards;
          })()}
        </Col>
      </Row>
    </>
  );
};

export const PlaceholderBannerDetail = () => {
  return (
    <Card>
      <Card.Header>
        <Placeholder as="h2" animation="glow">
          <Placeholder xs={2} />
        </Placeholder>
      </Card.Header>
      <Card.Body>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as="h3" animation="glow">
          <Placeholder xs={12} size="lg" style={{ height: 100 }} />
        </Placeholder>
        <Row>
          <Col xs={6}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
          <Col xs={6}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row className="justify-content-end">
          <Col xs={1}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
          <Col xs={1}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
          <Col xs={2}>
            <Placeholder as="h3" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export const PagePlaceholder = () => {
  return (
    <Row className="my-3 gap-3">
      <Col xs={12}>
        <Placeholder animation="glow">
          <Placeholder xs={1} />
        </Placeholder>{' '}
        /{' '}
        <Placeholder animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Col>

      <Col xs={12}>
        <PlaceholderTable />
      </Col>
    </Row>
  );
};
