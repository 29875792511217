import { faLanguage, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearMessage } from 'actions/message';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Footer from 'components/footer/Footer';
import { supportLink } from 'config';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

const AuthSimpleLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (['/login', '/register'].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  // Changing language
  const { t } = useTranslation();
  const toggleLanguage = () => {
    const currentLanguage = localStorage.getItem('languages');
    const newLanguage = currentLanguage === 'en' ? 'vi' : 'en';
    localStorage.setItem('languages', newLanguage);
    i18next.changeLanguage(newLanguage);
  };

  return (
    <>
      <Navbar className="navbar-glass bg-white navbar-top m-0 fw-normal">
        <Logo width={100} />
        <Nav className="ms-auto fs--1">
          <Nav.Link onClick={() => toggleLanguage()}>
            <FontAwesomeIcon icon={faLanguage} className="me-1" />
            {t('navigation.language')}
          </Nav.Link>
          <Nav.Link href={supportLink} target="_blank">
            <FontAwesomeIcon icon={faQuestionCircle} className="me-1" />
            {t('navigation.help_center')}
          </Nav.Link>
        </Nav>
      </Navbar>

      <div className="container-fluid">
        <div className="content">
          <Section className="pt-6">
            <Outlet />
          </Section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AuthSimpleLayout;
