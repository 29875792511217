import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { ActionIcon, type ActionIconProps } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import IconFA from 'components/common/IconFA';
import { useEffect, useState } from 'react';

type DownloadButtonProps = ActionIconProps & { url: string };

const DownloadButton = ({ url, ...rest }: DownloadButtonProps) => {
  const [downloaded, setDownloaded] = useState(false);
  const { start, clear } = useTimeout(() => {
    setDownloaded(false);
  }, 2000);

  const onClick = () => {
    setDownloaded(true);
    start();
    window.location.href = url;
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return (
    <ActionIcon
      color={downloaded ? 'teal' : 'gray'}
      variant="subtle"
      onClick={onClick}
      {...rest}
    >
      {downloaded ? <IconFA icon={'check'} /> : <IconFA icon={faDownload} />}
    </ActionIcon>
  );
};

export default DownloadButton;
