export type PackageItem = {
  _id?: string;
  name?: string;
  length?: string | number;
  width?: string | number;
  height?: string | number;
  weight?: string | number;
  type?: PackageTypeEnum;
  unit?: PackageUnitEnum;
  weight_unit?: WeightUniEnum;
  is_default?: boolean;
  updated_on?: number;
};
export enum PackageTypeEnum {
  BOX = 'box',
  ENVELOPE = 'envelope',
}
export enum PackageUnitEnum {
  FT = 'ft',
  IN = 'in',
}
export enum WeightUniEnum {
  LB = 'lb',
  OZ = 'oz',
}
