import { UPDATE_APP_IMAGE } from 'actions/types';

const initialState = {
  appImages: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_APP_IMAGE:
      return {
        ...state,
        appImages: { ...state.appImages, ...payload }
      };

    default:
      return state;
  }
}
