import { auth } from 'firebase-app';
import {
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink
} from 'firebase/auth';

const APP_URL = process.env.REACT_APP_ADMIN_URL ?? 'localhost:3000';

var getActionCodeSettingsForgotPassword = () => ({
  url: `${APP_URL}/login`,
  handleCodeInApp: true
});

const getActionCodeSettings = email => ({
  url: `${APP_URL}/login-with-link?email=${email}`,
  handleCodeInApp: true
});

export const useSignInWithEmailAndPassword = () => {
  const createUserWithEmailPassword = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      return result;
    } catch (error) {
      console.error('Create user with Email and Password: ', error);
    }
  };

  const signIn = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const sendPasswordReset = email =>
    sendPasswordResetEmail(auth, email, getActionCodeSettingsForgotPassword());
  return {
    createUserWithEmailPassword,
    signIn,
    sendPasswordReset
  };
};

export const useSignInWithEmailLink = () => {
  const sendSignInLink = email =>
    sendSignInLinkToEmail(auth, email, getActionCodeSettings(email));

  const signIn = async email => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      try {
        await signInWithEmailLink(auth, email, window.location.href);
      } catch (error) {
        console.error(error);
        throw new Error('Login failed');
      }
    }
  };

  return { sendSignInLink, signIn };
};
