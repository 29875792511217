import React from 'react';
import { Card, Tab, Nav } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import DashboardStatItem from 'components/common/DashboardStatItem';
import MultiCharts from './MultiCharts';
import dayjs from 'dayjs';
import CustomSelectDatePicker from './CustomSelectDatePicker';
import IconButton from 'components/common/IconButton';

export const DashCharts = ({
  title = '',
  tabsData = '',
  filter,
  onChangeFilter,
  selectedTab,
  onChangeTab,
  onExportClick
}) => {
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);
  const { t } = useTranslation();

  return (
    <Card className="overflow-hidden mb-3">
      <Card.Header>
        <div className="d-flex flex-row justify-content-between">
          <Card.Title className="mb-3">{title}</Card.Title>
          <div className="d-sm-flex gap-3">
            <IconButton
              variant="link"
              size="sm"
              icon="download"
              transform="shrink-3"
              onClick={onExportClick}
            >
              <span className="d-none d-sm-inline-block">
                {t('general.export')}
              </span>
            </IconButton>
          </div>
        </div>
        <CustomSelectDatePicker
          onChange={x => onChangeFilter({ ...filter, ...x })}
        />
      </Card.Header>
      <Card.Body className="pt-0">
        <Tab.Container onSelect={onChangeTab} defaultActiveKey={selectedTab}>
          <SimpleBarReact className="mb-3">
            <Card.Header className="p-0 bg-light">
              <Nav className="nav-tabs border-0 flex-nowrap chart-tab scrollbar">
                {tabsData?.map((tab, index) => {
                  return (
                    <Nav.Link
                      key={tab?.title + index}
                      className="mb-0 py-3 px-4"
                      eventKey={tab?.key}
                      style={{ width: '200px' }}
                    >
                      <DashboardStatItem
                        label={tab?.title}
                        note={tab?.description}
                        current={tab?.current}
                        previous={tab?.previous}
                        format={tab?.format}
                      />
                    </Nav.Link>
                  );
                })}
              </Nav>
            </Card.Header>
          </SimpleBarReact>

          <Tab.Content>
            {tabsData?.map((tab, index) => {
              return (
                <Tab.Pane key={index} unmountOnExit eventKey={tab?.key}>
                  <MultiCharts charts={tab?.charts} {...tab?.chartProps} />
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </Card.Body>
    </Card>
  );
};
