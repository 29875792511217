import { useRef, useEffect } from 'react';
import { useGetLogin } from './useGetLogin';

function useDocumentTitle(title, prevailOnUnmount = false) {
  const { user } = useGetLogin();

  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = !user
      ? `${title} | Nailzy Panel`
      : user?.type
      ? `${title} | Nailzy Admin Panel`
      : `${title} | Nailzy Seller Panel`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
}

export default useDocumentTitle;
