import { textTruncate } from 'helpers/utils';
import { useQueryClient } from '@tanstack/react-query';

export const useGetLogin = () => {
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([
    'getLogin',
    textTruncate(userLocalInfo?.data?.token, 10)
  ])?.data;

  return { user: userLocalInfo };
};
