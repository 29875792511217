import React from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { RsiProps } from 'react-spreadsheet-import/types/types';

const spreadSheetStyle = {
  colors: {
    rsi: {
      50: '#ffe6ef',
      100: '#ffa3c8',
      200: '#ff7ab4',
      300: '#ff52a3',
      400: '#ff2994',
      500: '#ff0088',
      600: '#d9007b',
      700: '#b3006b',
      800: '#8c0059',
      900: '#660044',
    },
  },
  components: {
    UploadStep: {
      baseStyle: {
        tableWrapper: {
          mb: '0.5rem',
          position: 'relative',
          h: '100px',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          padding: '0px',
        },
      },
      // variants: {
      //   rsi: {
      //     dialog: {
      //       minH: 'calc(var(--chakra-vh) - 20rem)',
      //       my: '2rem',
      //     },
      //   },
      // },
    },
  },
};

export const ReactSpreadSheet = <T extends string>(props: RsiProps<T>) => {
  return (
    <ReactSpreadsheetImport
      {...props}
      customTheme={props.customTheme ? props.customTheme : spreadSheetStyle}
    />
  );
};
