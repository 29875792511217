import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation } from '@tanstack/react-query';

import { HTTPMethods, StatusEnum, TResponse, TResponseList } from 'types';
import { apiCallV2 } from './ApiCallV2';

const PATH = '/admin/imports';

export enum ImportHistoryStatus {
  VALIDATED = 'validated',
  IMPORTED = 'imported',
  ERROR = 'error',
}

export type TGetProductImportHistoriesQuery = {
  page: number;
  limit: number;
  status?: StatusEnum;
  import_status?: string;
  file_import_id?: string;
};

export type TGetProductImportHistoriesRes = {
  _id: string;
  status: StatusEnum;
  import_status: ImportHistoryStatus;
  imported_user: {
    _id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  media: {
    _id: string;
    name: string;
    url: string;
  };
  product_count: number;
  created_on: number;
  updated_on: number;
};

export const importProductExcelKeys = createQueryKeys('import-product-excel', {
  list: (filters: TGetProductImportHistoriesQuery) => ({
    queryKey: [{ filters }],
    queryFn: (): Promise<TResponseList<TGetProductImportHistoriesRes>> => {
      const controller = new AbortController();
      return apiCallV2({
        params: filters,
        url: PATH + '/product-import-histories',
        signal: controller.signal,
      });
    },
  }),
});

type TValidateImportProductsReq = {
  store_names: string[];
  brand_names: string[];
  category_names: string[];
  sub_category_names: string[];
};

export type TValidateImportProductsRes = {
  sellers?: string[];
  brands?: string[];
  categories?: string[];
  subcategories?: string[];
  sellers_with_multiple_warehouses?: string[];
  sellers_without_warehouse?: string[];
};

/**
 * Validate store, brand, category, subcategory, warehouse in file excel products
 * @returns errors type {TValidateImportProductsRes} or {} if  passed
 */
export const useValidateImportProductExcel = () =>
  useMutation<
    TResponse<TValidateImportProductsRes>,
    Error,
    TValidateImportProductsReq
  >({
    mutationFn: (body) => {
      const controller = new AbortController();
      return apiCallV2({
        url: PATH + '/validate-metadata-products',
        method: HTTPMethods.POST,
        data: body,
        signal: controller.signal,
      });
    },
  });

type TImportProductsReq = {
  status?: StatusEnum;
  product_count: number;
  system_user_id: string;
  file_import_id: string;
};

export type TImportProductsRes = {
  id: string;
};

/**
 * Create upload history after validate file excel
 * @return id if success
 */
export const useCreateProductImportHistory = () =>
  useMutation<TResponse<TImportProductsRes>, Error, TImportProductsReq>({
    mutationFn: (body) => {
      const controller = new AbortController();
      return apiCallV2({
        url: PATH + '/product-import-histories',
        method: HTTPMethods.POST,
        data: body,
        signal: controller.signal,
      });
    },
  });

type OnDelete = (
  id: string,
  callBack: {
    onSuccess?: (arg: string | undefined) => void;
    onError?: (Error: unknown) => void;
  },
) => Promise<string>;

export const useDeleteProductImportHistory = () => {
  const onDelete: OnDelete = async (id, { onSuccess, onError }) => {
    try {
      const controller = new AbortController();
      const result = await apiCallV2({
        url: PATH + '/product-import-histories/' + id,
        method: HTTPMethods.DELETE,
        signal: controller.signal,
      });
      onSuccess?.(result);
      return result;
    } catch (error) {
      onError?.(error);
    }
  };

  return { onDelete };
};
