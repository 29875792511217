import SelectInput from 'components/common/inputs/SelectInput';
import { t } from 'i18next';
import { forwardRef } from 'react';

const StatusEnums = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  CLOSED: 'closed'
};

export const StatusOptions = [
  {
    value: StatusEnums.ACTIVE,
    label: `general.${StatusEnums.ACTIVE}`
  },
  {
    value: StatusEnums.DRAFT,
    label: `general.${StatusEnums.DRAFT}`
  },
  {
    value: StatusEnums.CLOSED,
    label: `general.${StatusEnums.CLOSED}`
  }
];

const StatusInput = forwardRef(({ ...props }, ref) => {
  return (
    <SelectInput
      options={StatusOptions?.map(x => ({ ...x, label: t(x.label) }))}
      ref={ref}
      {...props}
    />
  );
});

export default StatusInput;
