import { Flex, Group, Radio } from '@mantine/core';
import { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
  className?: string;
  value?: string;
  defaultValue?: string;
  label?: string;
  data: { label: ReactNode; value: string }[];
  required?: boolean;
  disabled?: boolean;
  direction?: 'horizontal' | 'vertical';
  onChange?: (value: string) => void;
};
const RadioGroupInput = (props: PropType) => {
  // ### CONSTANTs
  const {
    data = [],
    className = '',
    defaultValue = '',
    required,
    label,
    disabled,
    direction = 'horizontal',
    onChange,
    value,
    ...els
  } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Radio.Group
      className={className}
      onChange={onChange}
      {...els}
      label={label}
      withAsterisk={required}
      defaultValue={defaultValue || data?.[0]?.value}
      value={value || undefined}
    >
      <Group mt="xs">
        <Flex
          gap={direction === 'horizontal' ? 'sm' : ''}
          direction={direction === 'horizontal' ? 'row' : 'column'}
        >
          {data?.map((item) => {
            return (
              <Radio
                disabled={disabled}
                key={item?.value}
                value={item?.value}
                label={item?.label}
              />
            );
          })}
        </Flex>
      </Group>
    </Radio.Group>
  );
};
export default RadioGroupInput;
