import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';

const PaymentModal = ({ onHide, visible, data, total }) => {
  if (!data) return null;
  const {
    publishableKey,
    paymentIntent: clientSecret,
    ephemeralKey,
    stripeCustomerId
  } = data;
  const { t } = useTranslation();

  const stripePromise = loadStripe(publishableKey, {
    apiVersion: '2023-10-16',
    betas: ['elements_customers_beta_1']
  });
  const options = {
    clientSecret,
    loader: 'always',
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: '#ff0088',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        gridRowSpacing: '24px',
        fontLineHeight: '1.5',
        borderRadius: '10px',
        colorBackground: '#F6F8FA',
        accessibleColorOnColorPrimary: '#262626'
      },
      rules: {
        '.Block': {
          boxShadow: 'none',
          padding: '12px'
        },
        '.Input': {
          padding: '12px'
        },
        '.Input:disabled, .Input--invalid:disabled': {
          color: 'lightgray'
        },
        '.Tab': {
          padding: '10px 12px 8px 12px',
          border: 'none'
        },
        '.Tab:hover': {
          border: 'none',
          boxShadow:
            '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
        },
        '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
          border: 'none',
          backgroundColor: '#fff',
          boxShadow:
            '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
        },
        '.Label': {
          fontWeight: '500'
        }
      }
    },
    customerOptions: {
      customer: stripeCustomerId,
      ephemeralKey: ephemeralKey
    }
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <Modal show={visible} onHide={onHide} centered size="md">
        <PaymentForm onHide={onHide} total={total} />
      </Modal>
    </Elements>
  );
};
export default PaymentModal;
