import { useSyncTrackingStatus } from 'apis/OrdersApi';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IconFA from 'components/common/IconFA';
import { dateTimeFormatter } from 'helpers/utils';
import { OrderStatusEnum } from 'types';
import { Button, Flex, Text, Title } from '@mantine/core';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import OrderStatus from 'pages/orders/components/OrderStatus';

const OrderDetailsHeader = ({
  data: {
    code: orderUid,
    created_on,
    updated_on,
    order_status,
    shipment,
    payment_information,
  },
  handleCancelOrder,
  handlePrintPackingSlip,
}: any) => {
  const { t } = useTranslation();
  // Get current order
  const { orderId } = useParams();
  const createdOn = dateTimeFormatter(created_on);
  const updatedOn = dateTimeFormatter(updated_on);

  // Get packing slip URL
  const { mutate: syncTrackingStatus, isPending: isSyncing }: any =
    useSyncTrackingStatus(orderId);

  const hideSyncTrackingStatus =
    order_status !== OrderStatusEnum.SHIPPED ||
    shipment?.type === ShippingInformationTypeEnum.Custom;
  const hideCancelOrder =
    order_status !== OrderStatusEnum.PENDING &&
    order_status !== OrderStatusEnum.PROCESSING;

  const hideGetPaymentReceipt = !payment_information?.receipt_url;

  const receiptURL = payment_information?.receipt_url;

  return (
    <Flex direction={'column'} className="mb-3" gap={'xs'}>
      <Flex w="100%" direction={{ base: 'column', md: 'row' }}>
        <Flex gap={'xs'} flex={1} align={'center'}>
          <Title order={2}>{`${t('orders.order')} # ${orderUid}`} </Title>
          <CopyButton value={orderUid} />
        </Flex>
        <Flex>
          <DropdownButton
            button={
              <Button
                variant="default"
                size="xs"
                rightSection={<IconFA icon={faChevronDown} />}
              >
                {t('orders.actions')}
              </Button>
            }
            dropdownData={[
              {
                key: 'print_packing_list',
                label: t('orders.print_packing_list'),
                onClick: () => handlePrintPackingSlip(),
              },
              {
                key: 'sync_tracking_status',
                label: t('orders.sync_tracking_status'),
                onClick: () => syncTrackingStatus(),
                hide: hideSyncTrackingStatus || isSyncing,
              },
              {
                key: 'get_payment_receipt',
                label: t('orders.get_payment_receipt'),
                href: receiptURL,
                hide: hideGetPaymentReceipt,
              },
              {
                isDivider: true,
                hide: hideCancelOrder,
              },
              {
                key: 'cancel_order',
                label: t('orders.cancel_order'),
                color: 'red',
                hide: hideCancelOrder,
                onClick: () => handleCancelOrder(),
              },
            ]}
          />
        </Flex>
      </Flex>
      <Text size="xs">{createdOn}</Text>
      <OrderStatus status={order_status} />
    </Flex>
  );
};

export default OrderDetailsHeader;
