import { t } from 'i18next';

export const CustomerTitleEnums = {
  NAIL_SUPPLIER: 'nail_supply',
  NAIL_TECHNICIAN: 'nail_tech',
  SALON_OWNER: 'salon_owner',
  OTHER: 'other'
};

export const CustomerTitleTypeOptions = [
  {
    value: CustomerTitleEnums.NAIL_SUPPLIER,
    label: `customer.${CustomerTitleEnums.NAIL_SUPPLIER}`
  },
  {
    value: CustomerTitleEnums.NAIL_TECHNICIAN,
    label: `customer.${CustomerTitleEnums.NAIL_TECHNICIAN}`
  },
  {
    value: CustomerTitleEnums.SALON_OWNER,
    label: `customer.${CustomerTitleEnums.SALON_OWNER}`
  },
  {
    value: CustomerTitleEnums.OTHER,
    label: `customer.${CustomerTitleEnums.OTHER}`
  }
];
