import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/common/buttons/LoadingButton';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { currencyFormatter } from 'helpers/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const PaymentForm = ({ onHide, total }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const paymentElementOptions = {
    layout: {
      type: 'tabs',
      spacedAccordionItems: true,
      defaultCollapsed: false
    },
    business: { name: 'Nailzy' }
  };
  const onClickPay = async () => {
    setLoading(true);
    const res = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    if (res?.error) {
      toast.error(res?.error.message);
      setLoading(false);
    } else {
      onHide();
      toast.success(t('orders.payment_success'));
      queryClient.invalidateQueries({
        queryKey: ['useGetDraftCart']
      });
      onHide?.();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{`${t('orders.pay_with_credit')} `}</Modal.Title>
        <FalconCloseButton onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <PaymentElement
          id="payment-element"
          className="z-[1002]"
          options={paymentElementOptions}
        />
      </Modal.Body>

      <Modal.Footer>
        <LoadingButton
          variant="primary"
          isLoading={loading}
          onClick={onClickPay}
          size="md"
          className="w-100"
        >
          <span>
            {t('payouts.pay') + ' ' + currencyFormatter.format(total)}
          </span>
        </LoadingButton>
      </Modal.Footer>
    </>
  );
};
export default PaymentForm;
