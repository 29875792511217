import { useQuery, useMutation } from '@tanstack/react-query';
import { apiCall, apiCallFile, apiCallV2 } from './ApiCall';
import { dataFetchingQueryConfig } from 'config';
import queryString from 'query-string';
import { brandKeys } from 'query-keys/brand-keys';

const config = dataFetchingQueryConfig;
const API_END_POINT = 'admin/brands';

export const useGetBrands = (params = {}, key = '', enabled = true) => {
  const controller = new AbortController();
  const url = `${API_END_POINT}?${queryString.stringify(params)}`;

  return useQuery({
    queryKey: [brandKeys.list(params), key],
    queryFn: () => apiCallV2({ url, signal: controller.signal }),
    ...config,
    enabled: enabled,
  });
};

export const useGetBrandDetails = (_id) => {
  const controller = new AbortController();
  const url = `${API_END_POINT}/${_id}`;

  return useQuery({
    queryKey: brandKeys.detail(_id),
    queryFn: () => apiCallV2({ url, signal: controller.signal }),
    enabled: !!_id,
  });
};

export const useAddBrand = () => {
  const controller = new AbortController();
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = API_END_POINT;
      const headers = {};

      return apiCallV2({
        method: 'POST',
        url,
        data,
        headers,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

export const useUpdateBrand = () => {
  const controller = new AbortController();
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = API_END_POINT;
      const headers = {};
      // delete data._id;

      return apiCallV2({
        method: 'PUT',
        url,
        data: { brands: Array.isArray(data) ? data : [data] },
        headers,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

/**
 * Custom hook to add brand images using `useMutation` from `@tanstack/react-query`
 *
 * @param {Object} data - Object with brand_image: binary image file.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useAddBrandIMG = () => {
  const controller = new AbortController();
  const mutation = useMutation((data) => {
    const url = 'brands/upload_brand_media';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

/**
 * Export all brands to Excel TODO: need API route in backend
 */
export async function exportBrands(data = {}) {
  const controller = new AbortController();
  const url = 'brands/export_excel';
  const body = {
    ...data,
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Custom hook to delete a brand using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Object to send with the request includes _id
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useDeleteBrand = () => {
  const mutation = useMutation({
    mutationFn: (data) => {
      const controller = new AbortController();
      const url = `${API_END_POINT}/${data._id}`;
      const headers = {};

      return apiCallV2({
        method: 'DELETE',
        url,
        data,
        headers,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

export const useUpdateBrands = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = 'admin/brands';

      return apiCallV2({
        method: 'PUT',
        url,
        data: { brands: Array.isArray(data) ? data : [data] },
        signal: controller.signal,
      });
    },
  });

  return mutation;
};
