import { useMutation, useQuery } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCall';
import { dataFetchingQueryConfig } from 'config';
import { useMemo } from 'react';
import queryString from 'query-string';
import { objectToParams } from '../helpers/utils';

const config = dataFetchingQueryConfig;
export const useGetDraftCart = (params = {}) => {
  const controller = new AbortController();
  return useQuery({
    queryKey: ['useGetDraftCart', params],
    queryFn: () =>
      apiCallV2({
        url: 'admin/draft-carts',
        signal: controller.signal,
        params
      }),
    ...config
  });
};
export const useGetDraftCartDetails = _id => {
  const controller = new AbortController();
  return useQuery({
    queryKey: ['useGetDraftCartDetails', _id],
    queryFn: () =>
      apiCallV2({
        url: 'admin/draft-carts/' + _id,
        signal: controller.signal
      }),
    ...config
  });
};

export const useCreateDraftCart = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id, ...els } = data;
      const url = 'admin/draft-carts';
      return apiCallV2({
        url,
        data: els,
        method: 'POST'
      });
    }
  });
};
export const useUpdateDraftCart = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id, ...els } = data;
      const url = 'admin/draft-carts/' + _id;
      return apiCallV2({
        url,
        data: els,
        method: 'PUT'
      });
    }
  });
};
export const useGetDraftCartShippingMethod = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id, ...els } = data;
      const query = objectToParams(els);
      const url =
        `admin/draft-carts/${_id}/shipping` + (query ? `?${query}` : '');
      return apiCallV2({
        url,
        method: 'POST'
      });
    }
  });
};

export const useDeleteCarts = () => {
  return useMutation({
    mutationFn: ({ _id, ids }) => {
      const url = `admin/draft-carts/${_id}/carts`;
      return apiCallV2({
        url,
        data: { ids },
        method: 'delete'
      });
    }
  });
};

export const useAdminProductsMarketplace = (params = null, enabled = false) => {
  const url = 'admin/products/marketplace';
  const { data, isLoading, refetch, isRefetching, isFetching } = useQuery({
    queryKey: ['useAdminProduct', params],
    queryFn: () => apiCallV2({ url, params }),
    enabled: enabled,
    ...dataFetchingQueryConfig
  });
  const item = useMemo(() => {
    if (data?.data?.results?.length) {
      let array = [];
      for (let i = 0; i < data?.data?.results.length; i++) {
        const product = data?.data?.results[i];
        let variants = product?.variants?.map?.(x => ({
          ...x,
          product_id: product?._id,
          product_name: product?.name,
          variant_name: x?.name,
          variant_id: x?._id,
          _id: undefined
        }));
        array.push({ ...product, variants });
      }
      return array;
    } else return [];
  }, [data]);
  return {
    products: item,
    total: data?.data?.total || 0,
    data,
    loading: isLoading || isRefetching,
    isFetching,
    refetch
  };
};

export const useGetDraftCartPricingMethod = () => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id, sellers } = data;
      const url = `admin/draft-carts/${_id}/pricing`;
      return apiCallV2({
        url,
        data: { sellers },
        method: 'POST'
      });
    }
  });
};

export const useDeleteDraftCart = _id => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id } = data;
      const url = `admin/draft-carts/${_id}`;
      return apiCallV2({
        url,
        method: 'delete'
      });
    }
  });
};

export const useDraftCartPlaceOrder = _id => {
  return useMutation({
    mutationFn: (data = {}) => {
      const { _id, ...els } = data;
      const url = `admin/draft-carts/${_id}/place-orders`;
      return apiCallV2({
        url,
        method: 'post',
        data: els
      });
    }
  });
};

export const useGetPaymentIntent = _id => {
  return useMutation({
    mutationFn: (data = {}) => {
      const url = `marketplaces/orders/payment-intents`;
      return apiCallV2({
        url,
        method: 'post',
        data: data
      });
    }
  });
};
export const useGetDraftCartCoupons = draft_cart_id => {
  const controller = new AbortController();

  const url = `admin/draft-carts/${draft_cart_id}/coupons`;
  return useQuery({
    queryKey: ['useGetDraftCartCoupons', draft_cart_id],
    queryFn: () =>
      apiCallV2({
        url: url,
        signal: controller.signal
      }),
    ...config
  });
};
