import { Text } from '@mantine/core';
import { t } from 'i18next';
type PropType = {
  parcels?: any;
  className?: string;
};
const PackageParcelItems = (props: PropType) => {
  // ### CONSTANTs
  const { parcels } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <>
      {parcels?.map?.((parcel: any, index: number) => {
        return (
          <div key={index}>
            <Text size="sm">
              {t('orders.package')}
              {` ${index + 1}`}:{' '}
              {`${parcel?.length} x ${parcel?.width} x ${parcel?.height} ${parcel?.unit},  ${parcel?.weight} ${parcel?.weight_unit} `}
            </Text>
          </div>
        );
      }) || null}
    </>
  );
};
export default PackageParcelItems;
