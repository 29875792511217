import {
  Badge,
  Button,
  Flex,
  Grid,
  InputError,
  Modal,
  ScrollArea,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import {
  useCreatePermission,
  usePermissionActions,
  usePermissionDetails,
  useUpdatePermission,
} from 'apis/UserApis';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { PermissionActionItem, RoleItem, StatusEnum } from 'types';
import PermissionActionsCard from './PermissionActionsCard';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';
import { SectionFooter } from 'components/MantineUI/CommonSection';
type PropType = {
  data?: RoleItem;
  opened: boolean;
  onClose: () => void;
};
const RoleDetailsModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, data, onClose: onCloseProp } = props;
  const [name, setName] = useState<string>();
  const [requiredName, setRequiredName] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [selectedActions, setSelectedActions] = useState<string[]>([]);
  const { data: actionsData } = usePermissionActions();
  const { mutate: savePermission, isPending: saving } = useUpdatePermission();
  const { mutate: createPermission, isPending: creating } =
    useCreatePermission();
  const { data: roleDetail, isRefetching } = usePermissionDetails(data?._id);
  const actions: PermissionActionItem[] = actionsData?.data || [];
  // ### FUNCTIONs
  useEffect(() => {
    if (data?._id) {
      setName(roleDetail?.data?.name || '');
      setIsSuperAdmin(roleDetail?.data?.is_super_admin || false);
      setSelectedActions(roleDetail?.data?.actions);
    }
  }, [data?._id, roleDetail]);
  // ### RENDERs
  const onChangeAction = (values: string[]) => {
    setSelectedActions(values);
  };
  const onSave = () => {
    if (!name) {
      setRequiredName(true);
      return;
    }
    const params = {
      name: name,
      is_super_admin: isSuperAdmin,
      actions: selectedActions?.length ? selectedActions : undefined,
      status: data?.status || StatusEnum.ACTIVE,
    };
    if (data?._id) {
      savePermission(
        { ...params, id: data?._id },
        {
          onSuccess: () => {
            onClose?.();
          },
        },
      );
    } else {
      createPermission(params, {
        onSuccess: () => {
          onClose?.();
        },
      });
    }
  };
  const onClose = () => {
    setName('');
    setIsSuperAdmin(false);
    setSelectedActions([]);
    onCloseProp?.();
  };

  return (
    <Modal
      size={'80%'}
      centered
      opened={opened}
      onClose={onClose}
      title={
        data?._id ? t('system_users.edit_role') : t('system_users.add_role')
      }
    >
      <LoadingOverlayWrapper loading={isRefetching}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 'auto' }}>
            <TextInput
              required
              error={requiredName}
              errorProps={{ message: '123' }}
              label={t('system_users.role_name')}
              value={name}
              placeholder="Order manager"
              onChange={(e) => {
                if (e.target.value) setRequiredName(!e.target.value);
                setName(e.target.value);
              }}
            />
          </Grid.Col>
          <Grid.Col className="flex " span={{ base: 12, sm: 'content' }}>
            <Switch
              className="self-end"
              labelPosition="left"
              label={t('system_users.is_super_admin')}
              checked={isSuperAdmin}
              onChange={(e) => setIsSuperAdmin(e.target.checked)}
            />
          </Grid.Col>
          <>
            <Grid.Col className="pt-0" span={{ base: 12 }}>
              {requiredName ? (
                <InputError>
                  {t('form.invalid.required', {
                    fieldName: t('general.name'),
                  })}
                </InputError>
              ) : null}
            </Grid.Col>
            <Flex className="p-2 w-full">
              <Text className="flex-1" fw={500} size="sm">
                {t('system_users.permissions')}
              </Text>
              <div className="justify-center flex w-[100px]">
                <Badge variant="outline" color={'blue'}>
                  {t('system_users.action_get')}
                </Badge>
              </div>
              <div className="justify-center flex w-[100px]">
                <Badge variant="outline" color={'green'}>
                  {t('system_users.action_post')}
                </Badge>
              </div>
              <div className="justify-center flex w-[100px]">
                <Badge variant="outline" color={'orange'}>
                  {t('system_users.action_put')}
                </Badge>
              </div>
              <div className="justify-center flex w-[100px]">
                <Badge variant="outline" color={'red'}>
                  {t('system_users.action_delete')}
                </Badge>
              </div>
            </Flex>
            <ScrollArea w={'100%'} mah={600} className="border-top ">
              <Grid.Col span={{ base: 12 }}>
                {actions?.map((action, index) => (
                  <PermissionActionsCard
                    isSuperAdmin={isSuperAdmin}
                    key={index}
                    action={action}
                    selectedActions={selectedActions}
                    onChange={onChangeAction}
                  />
                ))}
              </Grid.Col>
            </ScrollArea>
          </>
        </Grid>
      </LoadingOverlayWrapper>
      <SectionFooter className="mt-3" updatedTime={data?.updated_on}>
        <Button loading={saving || creating} onClick={onSave}>
          {t('general.save')}
        </Button>
        <Button
          disabled={saving || creating}
          variant="default"
          onClick={onClose}
        >
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};
export default RoleDetailsModal;
