export const userKeys = {
  all: ['users'],
  lists: () => [...userKeys.all, 'list'],
  list: filters => [...userKeys.lists(), { filters }],
  details: () => [...userKeys.all, 'details'],
  detail: id => [...userKeys.details(), id],
  note: ['user-notes'],
  noteLists: () => [...userKeys.note, 'list'],
  noteList: filters => [...userKeys.noteLists(), { filters }]
};
