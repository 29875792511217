import React, { ReactNode } from 'react';
import { Button } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';
type PropType = {
  onClick?: () => void;
  className?: string;
};
const FilterButton = (props: PropType) => {
  // ### CONSTANTs
  const { onClick, className = '' } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Button className={className} variant="default" onClick={onClick} size="xs">
      <IconFA icon="filter" type="light" />
      <span className="hidden sm:block ms-2">{t('filter.filters')}</span>
    </Button>
  );
};
export default FilterButton;
