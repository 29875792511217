import React from 'react';
import IconButton from 'components/common/IconButton';
import FormatSelectOptionLabelWithStatus from 'components/common/inputs/FormatSelectOptionLabel';
import SelectInput from 'components/common/inputs/SelectInput';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { removeEmptyFields } from 'helpers/utils';
import { toast } from 'react-toastify';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useExportCustomers, useGetCustomers } from 'apis/CustomersApi';
import dayjs from 'dayjs';
import { useURLParams } from 'hooks/useURLParams';
import { StatusOptions } from 'components/common/inputs/StatusInput';
import { CustomerTitleTypeOptions } from 'constants/customers';
import {
  Button,
  Grid,
  Modal,
  NumberInput,
  Select,
  Switch,
  TextInput,
} from '@mantine/core';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import RadioGroupInput from 'components/MantineUI/Inputs/RadioGroupInput';
import RenderOptionWithStatus from 'components/common/inputs/RenderOptionWithStatus';
import { DatePickerInput } from '@mantine/dates';

type PropsType = {
  visible: boolean;
  onHide: () => void;
  current_items: any[];
};
const ExportCustomerModal = ({ visible, onHide, current_items }: PropsType) => {
  const { t } = useTranslation();
  const { params }: any = useURLParams();
  const { page, limit, status, title_type } = params;

  const [dateRange, setDateRange] = useState([]);
  // const [endDate, setEndDate] = useState();
  const [filterData, setFilterData] = useState({
    type: 'current_page',
    page: 1,
    limit: 500,
  });
  const {
    data,
    refetch: downloadCustomers,
    isLoading: exporting,
    isRefetching,
  } = useExportCustomers(
    filterData?.type === 'current_page'
      ? {
          page: parseInt(page),
          limit: parseInt(limit),
          status: status || undefined,
          title_type: title_type || undefined,
          ['date_range[from]']: params?.['date_range[from]'] || undefined,
          ['date_range[to]']: params?.['date_range[to]'] || undefined,
        }
      : {
          ...removeEmptyFields(filterData),
          ['date_range[from]']: dateRange?.[0]
            ? dayjs(dateRange?.[0]).unix()
            : undefined,
          ['date_range[to]']: dateRange?.[1]
            ? dayjs(dateRange?.[1]).unix()
            : undefined,
          type: undefined,
        },
  );
  const isLoading = exporting || isRefetching;

  const handleSubmitBtn = () => {
    downloadCustomers().then((res: any) => {
      if (res?.data?.status) {
        window.open(res?.data?.message, '_blank');
        toast.success(t('general.export_success'));
      } else {
        toast.error(t('general.export_fail'));
      }
    });
  };

  return (
    <Modal
      title={`${t('customer.export_customer')} ${name}`}
      opened={visible}
      onClose={onHide}
      centered
      size="lg"
    >
      <RadioGroupInput
        value={filterData?.type}
        data={[
          {
            label: `${t('orders.current_page')} (${current_items} ${t(
              'navigation.customers',
            ).toLowerCase()})`,
            value: 'current_page',
          },
          {
            label: t('filter.filters'),
            value: 'filters',
          },
        ]}
        onChange={(value) =>
          setFilterData({
            ...filterData,
            type: value,
          })
        }
        direction="vertical"
      />
      {filterData?.type === 'current_page' ? null : (
        <>
          <Grid>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Select
                label={t('general.status')}
                data={StatusOptions?.map((x) => ({
                  ...x,
                  label: t(x.label),
                }))}
                onChange={(value) => {
                  setFilterData((prev) => ({
                    ...prev,
                    status: value,
                  }));
                }}
                placeholder={t('orders.select_status')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <Select
                label={t('filter.role')}
                data={CustomerTitleTypeOptions?.map((x) => ({
                  ...x,
                  label: t(x.label),
                }))}
                onChange={(value) => {
                  setFilterData((prev) => ({
                    ...prev,
                    title_type: value,
                  }));
                }}
                placeholder={t('general.select_role')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12 }}>
              <DatePickerInput
                type="range"
                allowSingleDateInRange
                label={t('filter.joined_date_range')}
                placeholder={t('general.select_date_range')}
                key={'date_range'}
                onChange={(v: any) => setDateRange(v)}
                maxDate={new Date()}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <NumberInput
                label={t('general.page')}
                min={1}
                decimalScale={0}
                disabled={filterData?.type === 'current_page'}
                value={
                  filterData?.type === 'current_page'
                    ? parseInt(page)
                    : filterData?.page
                }
                onChange={(e) =>
                  setFilterData((prev: any) => ({
                    ...prev,
                    page: e,
                  }))
                }
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <NumberInput
                label={t('general.limit')}
                min={1}
                decimalScale={0}
                max={500}
                placeholder={t('general.all')}
                disabled={filterData?.type === 'current_page'}
                value={
                  filterData?.type === 'current_page'
                    ? parseInt(limit)
                    : filterData?.limit
                }
                onChange={(e) =>
                  setFilterData((prev: any) => ({
                    ...prev,
                    limit: e,
                  }))
                }
              />
            </Grid.Col>
          </Grid>
        </>
      )}

      <SectionFooter className="mt-3">
        <Button loading={isLoading} onClick={handleSubmitBtn}>
          {t('general.export')}
        </Button>
        <Button variant="default" onClick={onHide}>
          {t('general.close')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};
export default ExportCustomerModal;
