import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, localeDate, rgbaColor } from 'helpers/utils';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const tooltipFormatter = params => {
  return `
<div>
    <h6 class="fs--1 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${
      params[0].borderColor ? params[0].borderColor : params[0].color
    }"></span>
      ${localeDate(params[0].name)} : ${params[0].value}
    </h6>
</div>
`;
};

export const ChartItem = ({ valueX, valueY, isLoading, height }) => {
  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [10, 15],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-200'),
      textStyle: getColor('dark'),
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: valueX,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: {
        show: true,
        length: 10,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => dayjs(localeDate(value)).format('MMM DD'),
        margin: 15,
        align: 'left',
        // showMaxLabel: true,
        padding: [0, 0, 0, 0]
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      // position: 'right',
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLine: { show: false },
      axisLabel: { color: getColor('gray-400'), padding: [0, 5, 0, 0] }
    },
    series: [
      {
        type: 'line',
        data: valueY,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        smooth: true,
        emphasis: {
          scale: true
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.5)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: { right: '5%', left: '3%', bottom: '10%', top: '5%' }
  });

  return (
    <>
      {isLoading ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="2x"
            className="text-400"
          />
        </div>
      ) : (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: height }}
        />
      )}
    </>
  );
};

ChartItem.propTypes = {
  valueX: PropTypes.array,
  valueY: PropTypes.array,
  isLoading: PropTypes.bool
};
