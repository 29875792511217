import {
  GoogleAuthProvider,
  linkWithPopup,
  signInWithPopup,
  unlink
} from 'firebase/auth';

import { auth } from 'firebase-app';

const provider = new GoogleAuthProvider();

export const useSignInWithGoogle = () => {
  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      return result.user;
    } catch (error) {
      console.error('Google sign-in failed:', error);
    }
  };

  const linkAccount = async () => {
    try {
      await linkWithPopup(auth.currentUser, provider);
    } catch (error) {
      console.error('Google link failed:', error);
      throw error;
    }
  };

  const unlinkAccount = async () => {
    try {
      await unlink(auth.currentUser, 'google.com');
      auth.currentUser?.getIdToken(true);
    } catch (error) {
      console.error('Google unlink failed:', error);
    }
  };

  return {
    signIn,
    linkAccount,
    unlinkAccount
  };
};
