import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { t } from 'i18next';
import dayjs from 'dayjs';

const CustomSelectDatePicker = ({ onChange }) => {
  const [mode, setMode] = useState('7day');
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(
    dayjs().subtract(1, 'day').endOf('day'),
  );
  useEffect(() => {
    onChange?.({ startDate, endDate });
  }, [startDate, endDate]);
  useEffect(() => {
    switch (mode) {
      case '7day':
        setStartDate(dayjs().subtract(7, 'day').startOf('day'));
        setEndDate(dayjs().subtract(1, 'day').endOf('day'));
        break;
      case '30day':
        setStartDate(dayjs().subtract(30, 'day').startOf('day'));
        setEndDate(dayjs().subtract(1, 'day').endOf('day'));
        break;
      case '90day':
        setStartDate(dayjs().subtract(90, 'day').startOf('day'));
        setEndDate(dayjs().subtract(1, 'day').endOf('day'));
        break;
      // case '360day':
      //   setStartDate(dayjs().add(-360, 'day').startOf('day'));
      //   setEndDate(dayjs().endOf('day'));
      //   break;
    }
  }, [mode]);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start && dayjs(start));
    setEndDate(end && dayjs(end).endOf('day'));
    setMode('custom');
  };

  const dateOptions = [
    {
      label: t('dashboard.last_x_day', { days: 7 }),
      value: '7day',
    },
    {
      label: t('dashboard.last_x_day', { days: 30 }),
      value: '30day',
    },
    {
      label: t('dashboard.last_x_day', { days: 90 }),
      value: '90day',
    },
    // {
    //   label: t('dashboard.last_x_day', { days: 360 }),
    //   value: '360day'
    // },
    {
      label: t('dashboard.custom'),
      value: 'custom',
    },
  ];
  return (
    <Row className="g-3">
      <Col md={3}>
        <Form.Select
          value={mode}
          className="me-2"
          onChange={(e) => setMode(e.target.value)}
        >
          {dateOptions?.map((x, i) => (
            <option key={x.value} value={x.value}>
              {x?.label}
            </option>
          ))}
        </Form.Select>
      </Col>

      <Col md={5}>
        <DatePicker
          selected={startDate?.toDate?.()}
          onChange={onChangeDate}
          startDate={startDate?.toDate?.()}
          formatWeekDay={(day) => day.slice(0, 3)}
          endDate={endDate?.toDate?.()}
          selectsRange
          dateFormat="MMMM dd, yyyy"
          className="form-control"
        />
      </Col>
    </Row>
  );
};
export default CustomSelectDatePicker;
