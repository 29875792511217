import { Badge, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { getColor } from 'helpers/utils';
import { t } from 'i18next';
import { CartStatuses } from 'types';

const CartStatus = ({ status }) => {
  const statusConfig = CartStatuses?.[status];
  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <Badge
        fullWidth
        variant="light"
        color={getColor(statusConfig?.bg)}
        leftSection={
          <IconFA
            icon={statusConfig?.icon}
            color={getColor(statusConfig?.bg)}
          />
        }
      >
        <Text fw={600} size="xs">
          {t(statusConfig?.label)}
        </Text>
      </Badge>
    </div>
  );
};

export default CartStatus;
