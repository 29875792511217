import {
  SAVE_PAYMENTS_SUCCESS,
  SAVE_PAYMENTS_FAILURE,
  GETTING_PAYMENTS_DATA
} from 'actions/types';

const initialState = {
  data: null,
  isLoading: null,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTING_PAYMENTS_DATA:
      return { ...state, isLoading: true };
    case SAVE_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.response,
        error: null
      };
    case SAVE_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
}
