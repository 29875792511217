import { Suspense } from 'react';

const Loadable = (Component, fallback) => props =>
  (
    <Suspense fallback={fallback ? fallback : <div></div>}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
