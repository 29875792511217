import { Alert, Anchor, Badge, Button, Flex, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { currencyFormatter } from 'helpers/utils';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
type PropType = {
  shipment?: any;
  className?: string;
};
const ShippingLabelItems = (props: PropType) => {
  // ### CONSTANTs
  const { shipment, className = '' } = props;
  const shippo_rate = shipment?.shippo_rate;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div>
      {(shipment?.shippo_transactions?.length
        ? shipment?.shippo_transactions
        : shipment?.shippo_transaction
        ? [shipment?.shippo_transaction]
        : []
      )?.map?.((item: any, index: number) => {
        return (
          <div key={index} className="mb-2">
            <Flex align={'center'}>
              <Anchor
                size="sm"
                fw={'600'}
                target="_blank"
                component={Link}
                to={item?.tracking_url_provider}
              >
                {item?.tracking_number}
              </Anchor>
              <CopyButton value={item?.tracking_number} />
              <Badge color="blue" variant="light">
                {item?.tracking_status?.status || item?.tracking_status}
              </Badge>
            </Flex>
            {item?.tracking_status?.status_details ? (
              <>
                <Text size="sm">
                  {t('general.status')}: {item?.tracking_status?.status_details}
                </Text>
                {item?.tracking_status?.substatus?.action_required ? (
                  <Alert
                    icon={<IconFA icon={'triangle-exclamation'} />}
                    color="red"
                    className="my-2"
                  >
                    {item?.tracking_status?.substatus?.text}
                  </Alert>
                ) : null}
              </>
            ) : null}
            <div>
              <Button
                size="xs"
                variant="outline"
                onClick={() => window?.open(item?.label_url)}
              >
                {t('orders.print_shipping_label')}
              </Button>
            </div>
          </div>
        );
      }) || null}

      {shippo_rate ? (
        <>
          <Text size="sm">
            {shippo_rate?.provider} {shippo_rate?.servicelevel_name}{' '}
          </Text>
          <Text size="sm">
            {t('orders.est_delivery')}: {shippo_rate?.estimated_days}{' '}
            {t('orders.business_days')}
          </Text>
          <Text size="sm">
            {t('orders.amount')}:{' '}
            {currencyFormatter.format(shippo_rate?.amount)}
          </Text>
        </>
      ) : null}
    </div>
  );
};
export default ShippingLabelItems;
