import { Button } from '@mantine/core';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import { useMemo } from 'react';
import { KeyLabelPair } from 'types';

type Props = {
  sortOptions?: KeyLabelPair[];
  disabled?: boolean;
  fieldName?: string;
};
const SortButton = ({
  sortOptions = [],
  disabled,
  fieldName = 'sort',
}: Props) => {
  const { params, handleParamChange } = useURLParams() as any;

  const defaultOptions = [
    { label: t('general.name_asc'), key: 'name_asc' },
    { label: t('general.name_desc'), key: 'name_desc' },
    { label: t('general.created_desc'), key: 'created_on_desc' },
    { label: t('general.created_asc'), key: 'created_on_asc' },
    { label: t('general.updated_desc'), key: 'updated_on_desc' },
    { label: t('general.updated_asc'), key: 'updated_on_asc' },
  ];

  // RENDER
  const dropdownData = useMemo(() => {
    const options = sortOptions?.length ? sortOptions : defaultOptions;

    return options.map((route, i) => ({
      onClick: () => {
        const sortValue =
          params?.[fieldName] === route?.key ? null : route?.key;
        handleParamChange(fieldName)(sortValue);
      },
      label: route?.label,
      key: route?.key,
    }));
  }, [sortOptions]);

  return (
    <DropdownButton
      disabled={disabled}
      selected={params?.[fieldName]}
      button={
        <Button size="xs" variant="default">
          <IconFA icon="arrow-down-short-wide" type="light" />
          <span className="hidden sm:block ms-2">{t('general.sort')}</span>
        </Button>
      }
      dropdownData={dropdownData}
    />
  );
};

export default SortButton;
