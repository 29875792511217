import { keepPreviousData } from '@tanstack/react-query';

export const company = 'Nailzy.com';
export const homepage = process.env.REACT_APP_HOMEPAGE; // https://nailzy.com
export const version = process.env.REACT_APP_VERSION;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
};

export const apiSetting = {
  LOGOUT_WAIT_TIME: 2000,
  CATCH_ERROR_TIME_OUT: 20000,
};

export const dataFetchingQueryConfig = {
  placeholderData: keepPreviousData,
  staleTime: 5 * 60 * 1000,
  retry: false,
};

export const useQueryConfig = {
  refetchOnWindowFocus: false,
};

export const errorMessages = {
  server: 'Problem occured! Please contact us at seller.support@nailzy.com',
};

export const template = {
  jobs: 'https://cdn.nailzy.com/templates/template-jobs.xlsx',
  techs: 'https://cdn.nailzy.com/templates/template-techs.xlsx',
  salons: 'https://cdn.nailzy.com/templates/template-salons.xlsx',
  products: 'https://cdn.nailzy.com/templates/template-products.xlsx',
  taxes: 'https://cdn.nailzy.com/templates/template-taxes.xlsx',
};

export const sellerAgreement =
  'https://cdn.nailzy.com/20230727_nailzy_seller_agreement.pdf';

export const supportLink = 'https://nailzy.com/help';

export default {
  company,
  homepage,
  version,
  navbarBreakPoint,
  topNavbarBreakpoint,
  settings,
  apiSetting,
  dataFetchingQueryConfig,
};
