import { Autocomplete, Loader } from '@mantine/core';
import React, { ReactNode, useCallback } from 'react';
import IconFA from 'components/common/IconFA';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
type PropType = {
  className?: string;
  label?: string;
  error?: ReactNode;
  defaultValue?: string;
  placeholder?: string;

  data: { value: string; label: string }[];
  onInput?: (text?: string) => void;
  onSelect?: (value: any) => void;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  renderOption?: any;
};
const AutoCompleteInput = (props: PropType) => {
  // ### CONSTANTs
  const {
    className = '',
    label,
    defaultValue,
    loading = false,
    data,
    onSelect,
    onInput,
    placeholder,
    renderOption,
    disabled,
    required,
    error,
  } = props;
  // ### FUNCTIONs
  const debouceOnInput = useCallback(
    lodash.debounce((event: any) => onInput?.(event?.target?.value || ''), 500),
    [],
  );

  // ### RENDERs
  return (
    <Autocomplete
      required={required}
      disabled={disabled}
      className={className}
      label={label}
      defaultValue={defaultValue}
      rightSection={
        loading ? (
          <Loader className="ms-1" size={15} />
        ) : (
          <IconFA className=" text-[10px]" icon="angles-up-down" />
        )
      }
      renderOption={renderOption}
      placeholder={placeholder}
      data={data}
      onOptionSubmit={(e) => {
        let item = data?.find((x) => x.value === e);
        onSelect?.(item);
      }}
      onInput={debouceOnInput}
      error={error}
    />
  );
};
export default AutoCompleteInput;
