import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { useTranslation } from 'react-i18next';

const DiscardWarningModal = ({
  showDiscardWarning,
  handleCloseModal,
  goBack
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showDiscardWarning} onHide={handleCloseModal} centered>
      <Modal.Header>
        <Modal.Title>{t('general.discard_all')}</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>{t('general.discard_all_desc')}</Modal.Body>
      <Modal.Footer>
        <Button variant="falcon-default" onClick={handleCloseModal}>
          {t('general.cancel')}
        </Button>
        <Button variant="danger" onClick={goBack}>
          {t('general.discard')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DiscardWarningModal.propTypes = {
  showDiscardWarning: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  goBack: PropTypes.func
};

export default DiscardWarningModal;
