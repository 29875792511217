import { Grid } from '@mantine/core';
import { useCustomerDetails } from 'apis/CustomersApi';
import PageLayout from 'components/MantineUI/PageLayout';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomerDetailsCard from './components/CustomerDetailsCard';
import CustomerInfoCard from './components/CustomerInfoCard';
import CustomerTabsCard from './components/CustomerTabsCard';
import CustomerSaleSummary from './components/CustomerSaleSummary';
import CustomerAddressBookCard from 'components/MantineUI/Cards/AddressBookCard';

const CustomerDetailsPage = () => {
  // ### CONSTANTs

  const { t } = useTranslation();
  const [key, setKey] = useState('orders');
  const { id } = useParams();
  const { data, error, isLoading }: any = useCustomerDetails(id);
  const customer = data?.data;
  // ### FUNCTIONs
  // ### RENDERs
  const title = customer?.first_name
    ? `${customer.first_name} ${customer.last_name}`
    : t('navigation.user_details');
  return (
    <SkeletonWrapper show={isLoading} skeleton={<SectionPageSkeleton />}>
      <PageLayout
        title={title}
        breadCrumds={[
          { title: t('navigation.customers'), href: '/customers' },
          { title: title },
        ]}
      >
        <Grid>
          <Grid.Col span={{ base: 12, md: 8, lg: 9 }}>
            <CustomerDetailsCard customer={customer} />
            <CustomerSaleSummary customer={customer} />
            <CustomerTabsCard customer={customer} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4, lg: 3 }}>
            <CustomerInfoCard customer={customer} />
            <CustomerAddressBookCard customer={customer} />
          </Grid.Col>
        </Grid>
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default CustomerDetailsPage;
