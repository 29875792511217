import {
  useCreateDownloadImageURL,
  useGenerateUrls,
  useSyncStatusImage,
} from 'apis/ImageApi';
import axios from 'axios';
import { isImage, resizeImageWithSize } from 'helpers/utils';
import { useState } from 'react';

/**
 * A custom hook that provides functions for requesting images and related operations.
 * @returns Object containing functions for image-related operations.
 */
// TODO: Should change to Media, not image
const useRequestImage = () => {
  const { mutateAsync: mutateGenerateURL } = useGenerateUrls();
  const { mutateAsync: mutateSyncImage } = useSyncStatusImage();
  const { mutateAsync: generateDownloadUrl } = useCreateDownloadImageURL();
  const [loading, setLoading] = useState();
  /**
   * Creates a single image ID based on the provided file.
   * @param file - The image file to upload.
   * @param subFolder - The subfolder for organizing images (default: "jobs").
   * @param isPublic - Whether the image should be public (default: false).
   * @returns A Promise that resolves to the created image ID or false if unsuccessful.
   */
  const createImgID = async (
    file,
    subFolder,
    isPublic = false,
    resizeOptions,
  ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      setLoading(true);
      const formData = new FormData();
      const controller = new AbortController();
      const formartedFile = isImage(file)
        ? await resizeImageWithSize(file, resizeOptions)
        : file; // resize and format
      let imageID = null;

      const { name, type, size } = formartedFile;
      const submitData = {
        name,
        subFolder,
        isPublic,
        contentLength: size,
        contentType: type,
      };

      // Generate url
      const { status, data } = await mutateGenerateURL(submitData);

      if (status) {
        const { id, fields, signedUrl } = data || {};

        for (const [key, value] of Object.entries(fields)) {
          formData.append(key.toString(), value.toString());
        }

        formData.append('file', formartedFile);

        // Upload to AWS
        const { status } = await axios.post(signedUrl, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          signal: controller.signal,
        });

        // Sync status
        if (status === 204) {
          await mutateSyncImage(id, {
            onSuccess: () => (imageID = id),
            onError: () => {
              throw new Error(`Sync image not success: ${name}!`);
            },
          });
        }
      }
      return imageID;
    } catch (err) {
      // console.error(`loi o day ${err}`);
      throw new Error(err.message);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Creates an array of image IDs based on the provided files.
   * @param extends createImgID
   * @param files - Array of image files or objects with an "id" property.
   * @returns A Promise that resolves to an array of image IDs.
   */
  const createImgIDs = async (
    files,
    subFolder,
    isPublic = false,
    resizeOptions = {},
  ) => {
    let imageIDs = [];
    try {
      if (!Array.isArray(files)) throw new Error('arg is not type Array!');
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file?._id) {
          imageIDs.push(file?._id);
        } else if (typeof file === 'string') {
          imageIDs.push(file);
        } else {
          const url = await createImgID(
            file,
            subFolder,
            isPublic,
            resizeOptions,
          );
          if (url) imageIDs.push(url);
        }
      }
      return imageIDs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * Generates download URLs for an array of image IDs.
   * @param ids - Array of image IDs.
   * @param isPrivate - if true, url will be expired in 15'
   * @returns A Promise that resolves to an object containing generated URLs.
   */
  const createURL = async (ids, isPrivate = false) => {
    try {
      const result = await generateDownloadUrl({ ids, isPrivate });

      if (result.status) {
        return result?.data;
      } else {
        throw new Error('Something went wrong generating image urls');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    createImgID,
    createImgIDs,
    createURL,
    loading,
  };
};

export default useRequestImage;
