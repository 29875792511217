import { syncImagePaths } from 'apis/ImagesApis';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FalconLightBox from './FalconLightBox';

const NailzyImage = ({
  image_url,
  className = '',
  lightBox = false,
  ...props
}) => {
  const { appImages } = useSelector((state) => state.image);
  const defaultUrl = 'https://cdn.nailzy.com/no-image.png';

  const [isError, setIsError] = useState(0);
  useEffect(() => {
    if (isError === 1) {
      // syncImagePaths([image_id]);
      setIsError(2);
    }
  }, [isError]);
  const src = isError > 1 ? defaultUrl : image_url || '';

  return lightBox ? (
    <FalconLightBox image={src}>
      <Image
        onError={() => {
          setIsError(isError + 1);
        }}
        {...props}
        className={`object-fit-cover ${className}`}
        src={src}
      />
    </FalconLightBox>
  ) : (
    <Image
      onError={() => {
        setIsError(isError + 1);
      }}
      {...props}
      className={`object-fit-cover ${className}`}
      src={src}
    />
  );
};
export default NailzyImage;
