import { OrderStatusEnum } from 'types';

export const ShippingInformationTypeEnum = {
  Shippo: 'shippo',
  Manual: 'manual',
  Custom: 'custom',
};

export const OrderPaymentStatusEnum = {
  ALL: '',
  UNPAID: 'unpaid',
  PARTIALLY_PAID: 'partially_paid',
  PAID: 'paid',
  REFUNDED: 'refunded',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAYMENT_FAILED: 'payment_failed',
};

export const PaymentStatuses = {
  [OrderPaymentStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: '',
  },
  [OrderPaymentStatusEnum.UNPAID]: {
    label: 'orders.unpaid',
    bg: 'gray',
  },
  [OrderPaymentStatusEnum.PARTIALLY_PAID]: {
    label: 'orders.partially_paid',
    bg: 'warning',
  },
  [OrderPaymentStatusEnum.PAID]: {
    label: 'orders.paid',
    bg: 'success',
  },
  [OrderPaymentStatusEnum.REFUNDED]: {
    label: 'orders.refunded',
    bg: 'gray',
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment',
    bg: 'danger',
  },
  [OrderPaymentStatusEnum.PAYMENT_FAILED]: {
    label: 'orders.payment_failed',
    bg: 'danger',
  },
};

export const CancelReason = {
  out_of_stock: 'orders.out_of_stock',
  customer_request: 'orders.customer_request',
  payment_error: 'orders.payment_error',
  other: 'orders.other',
  abandoned_checkout: 'orders.abandoned_checkout',
  wrong_address: 'orders.wrong_address',
  expensive_shipping: 'orders.expensive_shipping',
  long_shipping: 'orders.long_shipping',
  duplicate_order: 'orders.duplicate_order',
  change_of_mind: 'orders.change_of_mind',
  order_mistake: 'orders.order_mistake',
  better_price: 'orders.better_price',
  forgot_coupon: 'orders.forgot_coupon',
  payment_issue: 'orders.payment_issue',
  bad_reviews: 'orders.bad_reviews',
};
export const SHIPMENT_TYPE = {
  MANUAL: 'manual',
  SHIPPO: 'shippo',
  CUSTOM: 'custom',
};
export const SHIPMENT_TYPE_LABEL = {
  MANUAL: 'orders.shipment_manual',
  SHIPPO: 'orders.shipment_shippo',
  CUSTOM: 'orders.shipment_custom',
};
