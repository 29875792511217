import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useURLParams } from 'hooks/useURLParams';
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useTopLoading } from 'contexts/TopLoadingContext';
import DCInfo from './components/DCInfo';
import { ShippingAddress } from 'pages/orders/order-details/components';
import CustomerCard from '../../components/common/customerCard';
import { useEffect, useMemo, useState } from 'react';
import CouponCard from '../../components/common/couponCard';
import { amountFormatter } from 'helpers/utils';
import SelectProductModal from './components/SelectProductModal';
import SellerCard from './components/SellerCard';
import { useCreateDraftCart } from 'apis/DraftCartApi';
import { toast } from 'react-toastify';
import LoadingButton from '../../components/common/buttons/LoadingButton';
import { useQueryClient } from '@tanstack/react-query';
import { faHome } from '@fortawesome/pro-solid-svg-icons';

const AddDraftCartsPage = () => {
  const { t } = useTranslation();
  const { setIsFetching } = useTopLoading();
  useDocumentTitle(t('draft_carts.add_draft_carts'));
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { params, handleParamsChange } = useURLParams();
  const handlePaginationChange = pagination => {
    handleParamsChange(pagination);
  };
  const [customer, setCustomer] = useState();
  const [coupons, setCoupons] = useState();
  const [selectProductVisible, setSelectProductVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sellersData, setSellerData] = useState([]);
  const [cacheQuantity, setCacheQuantity] = useState({});
  const { mutate: createDraftCart, isPending: creatingDraftCart } =
    useCreateDraftCart();
  const address = useMemo(() => {
    let addr = customer?.address?.find(x => !!x?.is_default);
    return addr || customer?.address?.[0];
  }, [customer]);
  useEffect(() => {
    let array = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.seller?._id) {
        let seller = array?.find(x => x?._id === product?.seller?._id);
        if (seller) {
          seller.products.push(product);
        } else {
          array.push({
            _id: product?.seller?._id,
            store_name: product?.seller?.store_name,
            products: [product]
          });
        }
      }
    }

    setSellerData(array);
  }, [selectedProducts]);

  const onSelectCustomer = c => {
    setCustomer(c);
  };
  const handleParams = () => {
    let carts = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      for (let y = 0; y < product?.variants.length; y++) {
        const variant = product?.variants[y];
        carts?.push({
          product_id: product?._id,
          variant_id: variant?.variant_id,
          quantity: cacheQuantity?.[variant?.variant_id] || 1,
          items_total: variant?.discount_price
        });
      }
    }
    return {
      customer_id: customer?._id,
      shipping_address_id: address?._id,
      coupon_ids: coupons ? coupons?.map(x => x?._id) : undefined,
      carts
    };
  };
  const onSaveCart = () => {
    const params = handleParams();
    createDraftCart(params, {
      onSuccess: res => {
        if (res?.status) {
          toast.success(t('general.create_success'));
          navigate('/draft-carts/' + res?.data?._id);
          queryClient.invalidateQueries({ queryKey: ['useGetDraftCart'] });
        }
      }
    });
  };
  const onChangeQuantity = (product_id, variant_id, quantity) => {
    setCacheQuantity(prev => ({ ...prev, [variant_id]: quantity }));
  };
  const onDeleteVariant = variant => {
    const { product_id, variant_id } = variant;
    let clone = [...selectedProducts];
    let prodIndex = clone?.findIndex?.(x => x?._id === product_id);
    if (prodIndex > -1) {
      let array = clone?.[prodIndex]?.variants?.filter?.(
        x => x?.variant_id !== variant_id
      );
      if (array?.length) {
        clone[prodIndex].variants = array;
      } else {
        delete clone[prodIndex];
      }
    }
    setSelectedProducts(clone);
  };

  // RENDER
  const renderLine = (name, amout, type = 'default') => {
    return (
      <div className="d-flex flex-row align-item-center justify-content-between">
        <div className="fs-0  ">{name}</div>
        <div
          className={`fs-${type === 'total' ? '2 fw-semi-bold' : '1'} text-${
            type === 'discount' ? 'danger' : 'black'
          }`}
        >
          {amout
            ? (type === 'discount' ? '-' : '') + amountFormatter(amout)
            : '--'}
        </div>
      </div>
    );
  };

  return (
    <>
      <Row className="my-3">
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item
              linkAs={Link}
              linkProps={{ to: '/' }}
              className="fs--1"
            >
              <FontAwesomeIcon icon={faHome} /> {t('navigation.home')}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="fs--1"
              linkAs={Link}
              linkProps={{ to: '/draft-carts' }}
            >
              {t('draft_carts.draft_carts')}
            </Breadcrumb.Item>
            <Breadcrumb.Item active className="fs--1">
              {t('draft_carts.add_draft_carts')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="g-3">
        <Col xs={12} lg={8}>
          <DCInfo onSelect={() => setSelectProductVisible(true)} />
          {sellersData?.map((x, i) => (
            <SellerCard
              isAdd
              seller={x}
              key={x?._id}
              cacheQuantity={cacheQuantity}
              onChangeQuantity={onChangeQuantity}
              onDeleteVariant={onDeleteVariant}
            />
          ))}
          <SelectProductModal
            selectedProducts={selectedProducts}
            visible={selectProductVisible}
            onHide={() => setSelectProductVisible(false)}
            onSave={setSelectedProducts}
          />
        </Col>

        <Col xs={12} lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <CustomerCard customer={customer} onSave={onSelectCustomer} />
            <ShippingAddress
              disabled={!customer}
              canAdd
              shippingAddress={customer?.address?.[0]}
              customer={customer}
              onUpdateAddress={address => {
                setCustomer({ ...customer, address: [address] });
              }}
            />
            {/* <CouponCard coupons={coupons} onSave={setCoupons} /> */}
            <Card className="mb-3">
              <Card.Body className="d-flex flex-row-reverse gap-2 ">
                <LoadingButton
                  isLoading={creatingDraftCart}
                  disabled={!customer || !address || !selectedProducts?.length}
                  onClick={() => onSaveCart()}
                >
                  {t('draft_carts.save_draft')}
                </LoadingButton>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddDraftCartsPage;
