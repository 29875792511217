import {
  faArchive,
  faCheckCircle,
  faClock,
  faSave
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useTranslation } from 'react-i18next';

const STATUS_ENUM = {
  CLOSED: 'closed',
  DRAFT: 'draft',
  ACTIVE: 'active',
  PENDING: 'pending'
};

const StatusBadge = ({ status, withIcon = true, className }) => {
  const { t } = useTranslation();

  const STATUS_INFO = {
    [STATUS_ENUM.ACTIVE]: {
      icon: faCheckCircle,
      bg: 'success',
      label: t('general.active')
    },
    [STATUS_ENUM.DRAFT]: {
      icon: faSave,
      bg: 'info',
      label: t('general.draft')
    },
    [STATUS_ENUM.CLOSED]: {
      icon: faArchive,
      bg: 'secondary',
      label: t('general.closed')
    },
    [STATUS_ENUM.PENDING]: {
      icon: faClock,
      bg: 'warning',
      label: t('general.pending')
    }
  };

  const statusIcon = STATUS_INFO[status]?.icon ?? undefined;
  const bg = STATUS_INFO[status]?.bg ?? 'light';
  const label = STATUS_INFO[status]?.label ?? 'N/A';
  return (
    <SoftBadge pill bg={bg} className={className}>
      {label}
      {statusIcon && withIcon && (
        <FontAwesomeIcon
          icon={statusIcon}
          transform="shrink-2"
          className="ms-1"
        />
      )}
    </SoftBadge>
  );
};

export default StatusBadge;
