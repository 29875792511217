import {
  Button,
  Flex,
  Grid,
  Image,
  InputError,
  InputLabel,
  NativeSelect,
  Text,
  TextInput,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { BANNER_TARGET_INFO, BannerTargetType, StatusEnum } from 'types';
import {
  useAdminBannerDetails,
  useCreateAdminBanner,
  useUpdateAdminBanner,
} from 'apis/BannerApis';
import {
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CategorySelect from 'components/MantineUI/Selects/CategorySelect';
import BrandSelect from 'components/MantineUI/Selects/BrandSelect';
import SellerStoreSelect from 'components/MantineUI/Selects/SellerStoreSelect';
import useRequestImage from 'hooks/file/useGenerateImageId';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import { imageUrlToId } from 'pages/dashboard/components';
import CommonDropZone from 'components/MantineUI/CommonDropZone';
import * as yup from 'yup';
import SingleSelect from 'components/MantineUI/Selects/SingleSelect';
import SubCategorySelect from 'components/MantineUI/Selects/SubCategorySelect';
import RenderOptionWithStatus from 'components/common/inputs/RenderOptionWithStatus';

const HomeBannerDetails = () => {
  // ### CONSTANTs
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useAdminBannerDetails(id);
  const { mutate: createBanner, isPending: creating } = useCreateAdminBanner();
  const { mutate: updateBanner, isPending: updating } = useUpdateAdminBanner();
  const { createImgID }: any = useRequestImage();
  const [image, setImage] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const banner = data?.data;
  const form = useForm({
    initialValues: {
      name: '',
      status: StatusEnum.ACTIVE,
      target_type: BannerTargetType.NO_TARGET,
    } as any,
    validate: yupResolver(
      yup.object({
        start_date: yup.number().required(
          t('form.invalid.required', {
            fieldName: t('promotions.start_date'),
          }),
        ),
        end_date: yup.number().required(
          t('form.invalid.required', {
            fieldName: t('promotions.end_date'),
          }),
        ),
        image_id: yup.string().required(
          t('form.invalid.required', {
            fieldName: t('general.image'),
          }),
        ),
      }),
    ),
  });
  useEffect(() => {
    if (banner?._id) {
      const tg_id = banner?.target?.type
        ? banner?.target?.[
            BANNER_TARGET_INFO[banner?.target?.type as BannerTargetType]
              .id_field
          ]
        : undefined;
      setValues({
        name: banner?.name || undefined,
        status: banner?.status || undefined,
        start_date: banner?.start_date || undefined,
        end_date: banner?.end_date || undefined,
        image_id: banner?.image_url || undefined,
        target_item: {
          value: tg_id,
          label: banner?.target?.name || banner?.target?.store_name,
        },
        target_type: banner?.target?.type || BannerTargetType.NO_TARGET,
        target_id: tg_id,
        sub_target_name: banner?.target?.name || banner?.target?.store_name,
      });
      setImage({ url: banner?.image_url });
    } else {
      setValues({});
    }
  }, [banner]);
  const { getInputProps, getValues, setValues, onSubmit, errors, setErrors } =
    form;
  const values = getValues();
  // ### FUNCTIONs
  const onSave = async (values: any) => {
    setLoading(true);
    try {
      let params: any = {
        name: values?.name,
        status: values?.status,
        start_date: values?.start_date,
        end_date: values?.end_date,
      };
      if (
        values?.target_type !== BannerTargetType.NO_TARGET &&
        !values?.target_id
      ) {
        setErrors({
          target_id: t('form.coupon.please_select_target'),
        });
        setLoading(false);
        return;
      }

      const image_id = image?.src
        ? await createImgID(image, 'banners', false)
        : image_url;

      params.image_id = imageUrlToId(image_id);
      if (values?.target_type !== BannerTargetType.NO_TARGET) {
        params = {
          ...params,
          target: {
            type: values?.target_type,
            [BANNER_TARGET_INFO[values?.target_type as BannerTargetType]
              .id_field]: values?.target_id,
          },
        };
      } else {
        params.target = id ? null : undefined;
      }
      if (id) {
        updateBanner(
          { banners: [{ ...params, _id: id }] },
          {
            onSuccess: () => {
              // navigate('/home-banners');
            },
          },
        );
      } else {
        createBanner(params, {
          onSuccess: () => {
            navigate('/home-banners');
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };
  // ### RENDERs
  const title = banner?.name || id || t('promotions.add_banner');
  const target_id = values?.target_id;
  const target_item = values?.target_item;
  const image_url = image?.src ?? image?.url ?? image;
  return (
    <SkeletonWrapper show={isLoading} skeleton={<ListPageSkeleton />}>
      <PageLayout
        title={title}
        breadCrumds={[
          { title: t('navigation.banners'), href: '/home-banners' },
          { title: title },
        ]}
      >
        <form onSubmit={onSubmit(onSave)}>
          <SectionWrapper>
            <SectionHeader title={title}></SectionHeader>
            <SectionBody>
              <Grid>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <TextInput
                    required
                    label={t('general.name')}
                    placeholder="Big Sale 50% Off"
                    {...getInputProps('name')}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <StatusSelect {...getInputProps('status')} />
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <DateTimePicker
                    {...getInputProps('start_date')}
                    required
                    label={t('promotions.start_date')}
                    placeholder={t('general.select_date_time')}
                    value={
                      values?.start_date
                        ? dayjs.unix(values?.start_date).toDate()
                        : undefined
                    }
                    onChange={(value) => {
                      form.setValues({
                        start_date: dayjs(value).unix(),
                      });
                    }}
                    valueFormat="DD MMM YYYY hh:mm A"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <DateTimePicker
                    {...getInputProps('end_date')}
                    required
                    label={t('promotions.end_date')}
                    placeholder={t('general.select_date_time')}
                    value={
                      values?.end_date
                        ? dayjs.unix(values?.end_date).toDate()
                        : undefined
                    }
                    onChange={(value) => {
                      form.setValues({
                        end_date: dayjs(value).unix(),
                      });
                    }}
                    valueFormat="DD MMM YYYY hh:mm A"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <NativeSelect
                    required
                    label={t('form.coupon.target')}
                    {...getInputProps('target_type')}
                    onChange={(e) =>
                      setValues({
                        target_type: e.target.value,
                        target_id: undefined,
                        target_item: undefined,
                      })
                    }
                    data={[
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.NO_TARGET]?.label,
                        ),
                        value: BannerTargetType.NO_TARGET,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.CATEGORY]?.label,
                        ),
                        value: BannerTargetType.CATEGORY,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.SUBCATEGORY]
                            ?.label,
                        ),
                        value: BannerTargetType.SUBCATEGORY,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.BRAND]?.label,
                        ),
                        value: BannerTargetType.BRAND,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.SELLER]?.label,
                        ),
                        value: BannerTargetType.SELLER,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.PRODUCT]?.label,
                        ),
                        value: BannerTargetType.PRODUCT,
                      },
                      {
                        label: t(
                          BANNER_TARGET_INFO[BannerTargetType.PROMOTION]?.label,
                        ),
                        value: BannerTargetType.PROMOTION,
                      },
                    ]}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  {values?.target_type === BannerTargetType.CATEGORY ? (
                    <CategorySelect
                      required
                      value={target_item}
                      onChange={(cate) => {
                        setValues({
                          target_id: cate?.value,
                          target_item: cate,
                        });
                      }}
                    />
                  ) : values?.target_type === BannerTargetType.SUBCATEGORY ? (
                    <SubCategorySelect
                      required
                      value={target_item}
                      onChange={(sub) => {
                        setValues({
                          target_id: sub?.value,
                          target_item: sub,
                        });
                      }}
                    />
                  ) : values?.target_type === BannerTargetType.SELLER ? (
                    <SellerStoreSelect
                      required
                      value={{
                        label: values?.target_item?.label,
                        value: values?.target_item?.value,
                      }}
                      onChange={(target) =>
                        setValues({
                          target_id: target?.value,
                          target_item: target,
                        })
                      }
                    />
                  ) : values?.target_type === BannerTargetType.BRAND ? (
                    <BrandSelect
                      required
                      value={{
                        label: values?.target_item?.label,
                        value: values?.target_item?.value,
                      }}
                      onChange={(target) =>
                        setValues({
                          target_id: target?.value,
                          target_item: target,
                        })
                      }
                    />
                  ) : values?.target_type === BannerTargetType.PRODUCT ? (
                    <SingleSelect
                      required
                      label={t('orders.product')}
                      placeholder={t('general.select')}
                      route="/admin/products"
                      params={{ status: StatusEnum.ACTIVE }}
                      selected={target_item}
                      searchField="name"
                      onChange={(item) =>
                        setValues({ target_id: item?.value, target_item: item })
                      }
                    />
                  ) : values?.target_type === BannerTargetType.PROMOTION ? (
                    <SingleSelect
                      required
                      label={t('navigation.promotion')}
                      placeholder={t('general.select')}
                      route="/admin/promotions"
                      selected={target_item}
                      searchField="name"
                      renderOption={(option: any) => (
                        <RenderOptionWithStatus {...option} />
                      )}
                      optionTransformer={(data: any) =>
                        data?.results?.map((x: any) => ({
                          label: x.name,
                          value: x._id,
                          status: x.status,
                        }))
                      }
                      onChange={(item) =>
                        setValues({ target_id: item?.value, target_item: item })
                      }
                    />
                  ) : null}
                  {errors?.target_id ? (
                    <InputError className="mt-1">
                      {errors?.target_id}
                    </InputError>
                  ) : null}
                </Grid.Col>
                <Grid.Col span={{ base: 12, sm: 6 }}>
                  <InputLabel required>{t('general.image')}</InputLabel>
                  <CommonDropZone
                    maxFiles={1}
                    onFileChange={(files) => {
                      setImage(files?.[0]);
                      setValues({
                        image_id: (files?.[0] as any).src,
                      });
                    }}
                  >
                    {image_url ? (
                      <Image
                        className="width-full border rounded-3"
                        src={image_url}
                      />
                    ) : null}
                  </CommonDropZone>
                  {errors?.image_id ? (
                    <InputError className="mt-1">{errors?.image_id}</InputError>
                  ) : null}
                </Grid.Col>
              </Grid>
            </SectionBody>
            <SectionFooter updatedTime={banner?.updated_on}>
              <Button loading={loading || creating || updating} type="submit">
                {t('general.save')}
              </Button>
              <Button
                onClick={() => navigate('/home-banners')}
                variant="default"
              >
                {t('general.cancel')}
              </Button>
            </SectionFooter>
          </SectionWrapper>
        </form>
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default HomeBannerDetails;
