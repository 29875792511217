import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';

const SelectInput = React.forwardRef(
  ({ size, isInvalid, isDisabled, ...props }, ref) => {
    return (
      <Select
        {...props}
        isDisabled={isDisabled}
        ref={ref}
        clearIndicator
        components={{
          IndicatorSeparator: () => null
        }}
        styles={{
          input: baseStyles => ({
            ...baseStyles,
            padding: 0
          }),
          dropdownIndicator: baseStyles => ({
            ...baseStyles,
            padding: 0,
            color: '##303030 !important'
          }),
          clearIndicator: baseStyles => ({ ...baseStyles, padding: 0 }),
          valueContainer: baseStyles => ({
            ...baseStyles,
            padding: '0 !important'
          }),
          control: baseStyles => ({
            ...baseStyles,
            backgroundColor: isDisabled ? '#f5f5f5 !important' : undefined
          })
        }}
        classNamePrefix="react-select"
        classNames={{
          control: () =>
            classNames({
              'react-select__control--invalid': isInvalid,
              'form-control': true,
              'form-control-sm': size === 'sm',
              'form-select:disabled': true
            })
        }}
      />
    );
  }
);

export default SelectInput;
