export const customerKeys = {
  all: ['customer'],
  lists: () => [...customerKeys.all, 'list'],
  list: (filter = {}) => [...customerKeys.lists(), { ...filter }],
  details: () => [...customerKeys.all, 'detail'],
  detail: id => [...customerKeys.details(), id],
  infinite: () => [...customerKeys.all, 'infinite'],
  referralHistory: customerId => [
    ...customerKeys.all,
    'referral-history',
    customerId
  ]
};
