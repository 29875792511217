import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { dataFetchingQueryConfig } from 'config';
import { toast } from 'react-toastify';
import { t } from 'i18next';
const config = dataFetchingQueryConfig;

// SYSTEM USERS
export const useAdminBannerList = (params = {}) => {
  const url = `admin/banners`;
  return useQuery<any>({
    queryKey: ['useAdminBannerList', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params,
      }),
    ...config,
  });
};

export const useAdminBannerDetails = (id?: string) => {
  const url = `admin/banners/` + id;
  return useQuery<any>({
    queryKey: ['useAdminBannerDetails', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};

export const useCreateAdminBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const url = `admin/banners`;
      return apiCallV2({
        url,
        method: 'POST',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
        queryClient.invalidateQueries(['useAdminBannerList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message || t('general.create_fail'));
    },
    ...config,
  });
};

export const useUpdateAdminBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const { _id, ...data } = params;
      const url = `admin/banners`;
      return apiCallV2({
        url,
        method: 'PUT',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
        queryClient.invalidateQueries(['useAdminBannerList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message || t('general.create_fail'));
    },
    ...config,
  });
};

export const useDeleteAdminBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) => {
      const url = `admin/banners/` + id;
      return apiCallV2({
        url,
        method: 'DELETE',
        data: { id },
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.delete_success'));
        queryClient.invalidateQueries(['useAdminBannerList'] as any);
      }
    },
    onError: (res) => {
      toast.error(t('general.delete_fail'));
    },
    ...config,
  });
};
