import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getColor, rgbaColor } from 'helpers/utils';
import { tooltipFormatter, xLabelFormatter } from 'helpers/echart-utils';

const MultiCharts = ({
  charts = [[], []],
  dateMode = 'day',
  duration = 0,
  tab = 'total_order'
}) => {
  const chart1 = charts?.[0];
  const chart2 = charts?.[1];
  const days = chart1?.map(x => x.label) || [];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: getColor('dark'),
      borderWidth: 1,
      formatter: p => tooltipFormatter(p, dateMode, duration, tab),
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: days,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => xLabelFormatter(value, dateMode, duration, tab),
        margin: 20
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 20
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: [
      {
        name: 'Current',
        type: 'line',
        smooth: true,
        symbolSize: 10,
        itemStyle: {
          color: getColor('primary'),
          borderColor: getColor('white'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        symbol: 'circle',
        showSymbol: false,
        stack: 'product2',
        data: chart1?.map(x => x.value) || [],
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.2)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      },
      {
        name: 'Previous',
        type: 'line',
        smooth: true,
        itemStyle: {
          color: 'transparent',
          borderWidth: 0
        },
        lineStyle: {
          color: '#DDCACC',
          type: 'dashed'
        },
        symbol: 'circle',
        stack: 'product',
        data: chart2?.map(x => x.value) || [],
        areaStyle: {}
      }
    ],
    grid: { right: 20, left: 5, bottom: 5, top: 10, containLabel: true }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  );
};
export default MultiCharts;
