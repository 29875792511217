import {
  MantineSize,
  NativeSelect,
  Pagination,
  PaginationProps,
} from '@mantine/core';
import { CommonPaginationProps } from 'types';

type PropType = {
  pagination: CommonPaginationProps;
  className?: string;
  size?: MantineSize;
};
const CommonPagination = (props: PropType) => {
  // ### CONSTANTs
  const { className = '', pagination, size = 'sm' } = props;
  const {
    total = 0,
    page = 1,
    limit = 25,
    onPaginationChange,
  } = pagination || {};
  // ### FUNCTIONs
  const onChange = (newPage: number = page, newLimit: number = limit) => {
    if (typeof onPaginationChange === 'function') {
      onPaginationChange(newPage, newLimit);
    }
  };
  // ### RENDERs
  return (
    <div className={`flex justify-between items-center w-full ${className}`}>
      <NativeSelect
        value={limit.toString()}
        size={'xs'}
        data={['50', '100', '250', '500']}
        onChange={(e) => onChange(1, parseInt(e?.target?.value))}
      />
      <Pagination
        color="primary"
        size={size}
        radius={size}
        total={total <= limit ? 1 : Math.floor(total / limit) + 1}
        value={page}
        onChange={onChange}
      />
    </div>
  );
};
export default CommonPagination;
