import { t } from 'i18next';
import { Button, Card } from 'react-bootstrap';
import IconFA from '../IconFA';
import SelectInput from 'components/common/inputs/SelectInput';
import { useState } from 'react';
import CouponItem from './CouponItem';
import { getColor } from 'helpers/utils';
import { useGetDraftCartCoupons } from '../../../apis/DraftCartApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Loader } from '@mantine/core';

const CouponCard = ({ coupons = [], onSave, isCompleted, id }) => {
  const [tempCp, setTempCp] = useState(null);

  const { data: res, refetch, isRefetching } = useGetDraftCartCoupons(id);
  const couponList = res?.data || [];

  const isDisabled = (item) => {
    let sameProvider = coupons?.filter((x) => x?.provider === item?.provider);
    if (item?.provider === 'nailzy' && sameProvider?.length) {
      return true;
    } else if (item?.provider === 'seller') {
      let selected = sameProvider?.find(
        (x) => x?.seller?._id === item?.seller?._id,
      );
      if (selected) {
        return true;
      }
    }
    return false;
  };

  const couponOptions = couponList
    ?.filter((x) => !coupons?.find((y) => y?._id === x?._id))
    ?.map((item) => ({
      ...item,
      value: item?._id,
      label: item?.name,
      total: item?.value,
      isDisabled: isDisabled(item),
    }));
  const onSelectCoupon = (value) => {
    let found = couponList?.find((x) => x?._id === value?.value);
    if (found) {
      setTempCp(found);
    } else {
      setTempCp(null);
    }
  };
  const formatLabel = (item) => {
    return (
      <div>
        <CouponItem item={{ ...item, value: item?.total }} />
      </div>
    );
  };
  const onClickSave = () => {
    let array = [...(coupons || [])];
    array = array?.filter((x) => x?._id !== tempCp?._id);
    array.push(tempCp);
    onSave?.(array);
    setTempCp(null);
  };

  return isCompleted && !coupons?.length ? null : (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <h5>
          {t('form.coupon.coupon')}{' '}
          {isCompleted ? null : isRefetching ? (
            <Loader className="ms-1" size={15} />
          ) : (
            <IconFA onClick={refetch} icon={faArrowsRotate} size="sm" />
          )}
        </h5>
        {isCompleted ? null : (
          <Button
            className="ms-2"
            variant="falcon-default"
            size="sm"
            disabled={!tempCp}
            onClick={onClickSave}
          >
            {t('general.apply')}
          </Button>
        )}
      </Card.Header>
      <Card.Body className="pt-0">
        {isCompleted ? null : (
          <div className="d-flex flex-row align-items-center mb-2">
            <SelectInput
              size="sm"
              value={
                tempCp
                  ? {
                      ...tempCp,
                      label: tempCp?.name,
                      value: tempCp?._id,
                      total: tempCp?.value,
                    }
                  : null
              }
              className="flex-1"
              formatOptionLabel={formatLabel}
              options={couponOptions}
              placeholder={t('orders.select_coupons')}
              onChange={onSelectCoupon}
              isClearable
            />
          </div>
        )}
        <div className="fs--1">
          {coupons?.length
            ? coupons?.map((coupon, index) => (
                <div
                  key={'couponItems' + index}
                  className="d-flex flex-row align-items-center"
                >
                  <CouponItem item={coupon} />
                  <IconFA
                    icon={faTrash}
                    color={getColor('gray-500')}
                    onClick={() => {
                      let array = [...(coupons || [])];
                      array = array?.filter((x) => x?._id !== coupon?._id);
                      onSave?.(array);
                    }}
                  />
                </div>
              ))
            : null}
        </div>
      </Card.Body>
    </Card>
  );
};
export default CouponCard;
