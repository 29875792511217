export const orderKeys = {
  all: ['orders'],
  lists: () => [...orderKeys.all, 'list'],
  list: (filter = {}) => [...orderKeys.lists(), { ...filter }],
  details: () => [...orderKeys.all, 'detail'],
  detail: id => [...orderKeys.details(), id],
  packingSlip: id => ['packing-slip', id],
  shippingConfigs: () => ['shipping-configs'],
  infinite: () => [...orderKeys.all, 'infinite']
};
