import SoftBadge from '../SoftBadge';

const CouponItem = ({ item }) => {
  const { name, code, type, value, isDisabled } = item || {};

  return (
    <div
      className="d-flex flex-row align-items-center gap-1 w-100"
      style={
        isDisabled
          ? {
              opacity: 0.5
            }
          : {}
      }
    >
      <SoftBadge pill bg={'danger'}>
        <div className=" fs--1">
          -{type === 'percentage' ? `${value}%` : `$${value}`}
        </div>
      </SoftBadge>
      <div className={`flex-1 d-flex flex-row gap-1 align-items-center`}>
        <div className="fs--1 fw-semi-bold one-line-text">{name}</div>
        <div className="fs--2 fw-300 text-gray-2">{code}</div>
      </div>
    </div>
  );
};
export default CouponItem;
