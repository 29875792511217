export const RESOURCE_NAME = 'brands';

export const QUERY_KEY_GET_BRAND = 'brands';

export const brandKeys = {
  all: [QUERY_KEY_GET_BRAND],
  lists: () => [...brandKeys.all, 'list'],
  list: (params = {}) => [...brandKeys.lists(), params],
  details: () => [...brandKeys.all, 'detail'],
  detail: id => [...brandKeys.details(), id]
};
