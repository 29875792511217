import React, { ReactNode, useMemo, useState } from 'react';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import CropImageModal from 'components/common/CropImageModal';
import { convertUrlToFile, getAutoCropImg } from 'helpers/cropImage';

export type UploadWrapperProps = {
  children?: ReactNode;
  acceptZone?: ReactNode;
  rejectZone?: ReactNode;
  className?: string;
  maxFiles?: number;
  acceptType?: 'images' | 'files';
  resize?: { width: number; height: number };
  onFileChange?: (files: Blob[] | MediaSource[]) => void;
};
const UploadWrapper = (props: UploadWrapperProps) => {
  // ### CONSTANTs
  const {
    children,
    className = 'cursor-pointer',
    onFileChange,
    acceptZone,
    rejectZone,
    acceptType: acceptTypeProps = 'images',
    resize = { width: 2000, height: 769 },
    maxFiles = 10,
  } = props;
  const [imgToCrop, setImageToCrop] = useState(null);
  const acceptType = useMemo(() => {
    if (acceptTypeProps === 'images') return IMAGE_MIME_TYPE;
    else if (acceptTypeProps === 'files') return [];
  }, [acceptTypeProps]);
  // ### FUNCTIONs
  const onDrop = async (files: any[]) => {
    try {
      if (acceptTypeProps === 'images') {
        if (files?.length === 1) {
          setImageToCrop([URL.createObjectURL(files[0])] as any);
        } else if (files.length > 1) {
          const newImages = await Promise.all(
            files.map(async (x) => {
              try {
                const img = await getAutoCropImg(
                  x,
                  resize.width / resize.height,
                );
                const imgFile = await convertUrlToFile(img, x.name);
                return Object.assign(imgFile, {
                  src: URL.createObjectURL(imgFile),
                });
              } catch (err) {
                console.error('Error processing files:', x.name, err);
                throw err; // rethrow to catch it in the main try/catch
              }
            }),
          );
          onFileChange?.(newImages);
        }
      } else {
        onFileChange?.(files);
      }
    } catch (e) {
      console.error('An error occurred during files processing:', e);
      if (e instanceof Error) {
        console.error('Stack trace:', e.stack);
      }
    }
  };

  const onSave = async (croppedImage: Blob | MediaSource) => {
    const file = await Object.assign(croppedImage, {
      src: URL.createObjectURL(croppedImage),
    });
    onFileChange?.([file] as any);
    setImageToCrop(null);
  };
  // ### RENDERs
  return (
    <div className={`${className}`}>
      <Dropzone
        accept={acceptType}
        onDrop={onDrop}
        maxFiles={maxFiles}
        maxSize={15000000} // 10mb
      >
        <Dropzone.Idle>{children}</Dropzone.Idle>
        {acceptZone ? <Dropzone.Accept>{acceptZone}</Dropzone.Accept> : null}
        {rejectZone ? <Dropzone.Reject>{rejectZone}</Dropzone.Reject> : null}
      </Dropzone>

      <CropImageModal
        imgToCrop={imgToCrop}
        onHide={() => setImageToCrop(null)}
        onSave={(file: Blob | MediaSource) => onSave(file)}
        cropOptions={resize}
      />
    </div>
  );
};
export default UploadWrapper;
