import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { supportLink } from 'config';

const Error500 = ({ error }) => {
  const { t } = useTranslation();
  useDocumentTitle(t('error.error_500'));

  useEffect(() => {
    if (error) {
      toast.warning(`${error}`, {
        theme: 'colored'
      });
    }
  }, [error]);

  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {t('error.error_500_message')}
        </p>
        <hr />
        <p>
          {t('error.error_500_content')}
          <Link to={supportLink} target="_blank" className="ms-1">
            {t('error.contact_us')}
          </Link>
        </p>
      </Card.Body>
    </Card>
  );
};

Error500.propTypes = {
  error: PropTypes.object
};

export default Error500;
