import { useMutation, useQuery } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import { apiCallV2 } from './ApiCall';

const config = dataFetchingQueryConfig;

const API_END_POINT = 'admin/profile';

export const useRegisterSeller = () => {
  const controller = new AbortController();

  const mutation = useMutation((data = {}) => {
    const url = 'admin/sellers/register';
    const headers = {};

    return apiCallV2({
      method: 'POST',
      url,
      data,
      headers,
      signal: controller.signal
    });
  });

  return mutation;
};

export const getProfile = () => apiCallV2({ url: API_END_POINT });

export const useGetProfile = (key = [], queryOptions = {}) => {
  return useQuery({
    queryKey: key,
    queryFn: getProfile,
    ...queryOptions
  });
};

export const useUpdateProfile = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = API_END_POINT;
      delete data?.address?._id;

      return apiCallV2({
        url,
        data,
        method: 'PUT'
      });
    }
  });

  return mutation;
};
