import {
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  GETTING_PRODUCT_DATA
} from 'actions/types';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETTING_PRODUCT_DATA:
      return { ...state, isLoading: true };

    case SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.response,
        error: null
      };

    case SAVE_PRODUCT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    default:
      return state;
  }
}
