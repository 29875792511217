import { faEllipsis } from '@fortawesome/pro-light-svg-icons';
import { faDownload, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Badge, Button, Flex, Modal, Title } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ImportHistoryStatus,
  importProductExcelKeys,
  TValidateImportProductsRes,
  useCreateProductImportHistory,
  useDeleteProductImportHistory,
  useValidateImportProductExcel,
} from 'apis/ImportProductExcelApis';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import DownloadButton from 'components/MantineUI/Buttons/DownloadButton';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable } from 'components/MantineUI/CommonTable';
import { DeleteModal } from 'components/MantineUI/Modals';
import PageLayout from 'components/MantineUI/PageLayout';
import { ReactSpreadSheet } from 'components/MantineUI/ReactSpreadSheet';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { useAuth } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import { ExcelJS } from 'helpers/excels-utils';
import {
  capitalize,
  entriesFromObject,
  keysFromObject,
  titleCase,
} from 'helpers/utils';
import useRequestImage from 'hooks/file/useGenerateImageId';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Data,
  Fields,
  Result,
  RowHook,
} from 'react-spreadsheet-import/types/types';
import { toast } from 'react-toastify';
import { AddressInfo, CommonTableColumn, StatusEnum, UserRole } from 'types';
import {
  checkDimensionUnit,
  checkImage,
  checkNumberGreaterThanZero,
  checkOutterSpace,
  checkSecondGreaterThanFirst,
  checkStringNumeric,
  checkWeightUnit,
  fieldData,
  fieldMustBeNumeric,
  fieldMustTrim,
  generateFields,
  parseFloatFromString,
  parseToStringNumber,
  TFieldsKey,
  validateFields,
} from './utils';
import { t } from 'i18next';

const SHEET_NAME = 'Nailzy Products';
const FOLDER_NAME = 'products/imports';
const DEFAULT_ERROR_MSG = 'translation.error.error_500_content';
const TEMPLATE_URL = 'https://cdn.nailzy.com/Nailzy_Product_Template.xlsx';

type TGetProfileResponse = {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  profile_image_url: string;
  type: UserRole;
  phone_number: string;
  status: StatusEnum;
  department: string;
  address: AddressInfo;
  updated_on: number;
  created_on: number;
  permission: {
    _id: string;
    name: string;
    is_super_admin: true;
    actions: [];
  };
};

const BulkImportProducts = () => {
  const { t, i18n } = useTranslation();
  const { params }: any = useURLParams();
  const queryClient = useQueryClient();
  const pagination = usePaginationParams();

  // @ts-ignore: TODO: remove after change useAuth to .ts
  const { user }: { user: TGetProfileResponse } = useAuth();
  const { onDelete } = useDeleteProductImportHistory();
  const { createImgID } = useRequestImage();
  const { mutate: createHistory } = useCreateProductImportHistory();

  const { mutateAsync: validateImport, isPending: validatingImport } =
    useValidateImportProductExcel();

  const { data: res, isLoading } = useQuery(
    importProductExcelKeys.list(params),
  );

  const [deleteItem, setDeleteItem] = useState<{
    media_name: string;
    id: string;
  } | null>(null);

  const [errors, setErrors] = useState<TValidateImportProductsRes>({});
  const [showSheet, setShowSheet] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);

  const { results = [], total = 0 } = res?.status ? res.data : {};
  const title = t('products.bulk_import');
  const isValidateError = keysFromObject(errors).length > 0;

  const reactSpreadSheetFields: Fields<TFieldsKey> = useMemo(
    () => fieldData.map((element) => generateFields(t, element)),
    [i18n.language],
  );

  const tableColumns: CommonTableColumn<{
    id: string;
    media_id: string;
    media_name: string;
    media_url: string;
    product_count: number;
    user_id: string;
    user_name: string;
    user_email: string;
    updated_on: string;
    created_on: string;
    status: StatusEnum;
    import_status: ImportHistoryStatus;
  }>[] = useMemo(
    () => [
      {
        Header: t('products.import_id'),
        accessor: 'import_id',
        Cell: ({ id }) => (
          <Flex align={'center'}>
            <p>{id}</p>
            <CopyButton value={id} />
          </Flex>
        ),
      },
      {
        Header: t('products.file_id'),
        accessor: 'media_id',
        Cell: ({ media_id, media_url }) => (
          <Flex align={'center'}>
            <p>{media_id}</p>

            <CopyButton value={media_id} />

            <DownloadButton url={media_url} />
          </Flex>
        ),
      },
      {
        Header: t('products.file_name'),
        accessor: 'media_name',
        Cell: ({ media_name }) => media_name,
      },
      {
        Header: t('products.products'),
        accessor: 'products',
        Cell: ({ product_count }) => product_count,
      },
      {
        Header: t('general.created_by'),
        accessor: 'user_name',
        Cell: ({ user_name }) => user_name,
      },
      {
        Header: t('general.created_date'),
        accessor: 'created_on',
        Cell: ({ created_on }) => created_on,
      },
      // {
      //   Header: t('general.status'),
      //   accessor: 'status',
      //   Cell: ({ status }) => <StatusBadge fullWidth status={status} />,
      // },
      {
        Header: t('products.import_status'),
        accessor: 'import_status',
        Cell: ({ import_status }) => (
          <ImportStatusBadge import_status={import_status} />
        ),
      },
      {
        accessor: 'action',
        Cell: ({ media_name, id }) => (
          <DropdownButton
            button={
              <Button variant="subtle" color="gray" size="xs">
                <IconFA icon={faEllipsis} />
              </Button>
            }
            dropdownData={[
              {
                key: 'delete',
                color: 'red',
                label: t('general.delete'),
                onClick: () => {
                  setDeleteItem((_) => ({ media_name, id }));
                },
              },
            ]}
          />
        ),
      },
    ],
    [results, i18n.language],
  );

  const tableData = useMemo(
    () =>
      results.map((row) => ({
        id: row._id,
        media_id: row.media?._id,
        media_name: row.media?.name,
        media_url: row.media?.url,
        product_count: row.product_count,
        user_id: row.imported_user?._id,
        user_name: `${row.imported_user?.first_name} ${row.imported_user?.last_name}`,
        user_email: row?.imported_user?.email,
        created_on: `${dayjs(row?.created_on * 1000).format(
          'MMM DD, YYYY',
        )} at ${dayjs(row?.created_on * 1000).format('hh:mm A')}`,
        status: row?.status,
        import_status: row?.import_status,
      })),
    [results],
  );

  const handlDownloadTemplate = () => {
    window.location.href = TEMPLATE_URL;
  };

  const handleDeleteBtn = (id: string) => {
    onDelete(id, {
      onSuccess: () => {
        toast.success(t('general.delete_success'));
        setDeleteItem(null);
        queryClient.invalidateQueries({
          queryKey: importProductExcelKeys._def,
        });
      },
      onError: () => {
        toast.error(t('general.delete_fail'));
      },
    });
  };

  const matchColumnsStepHook = async (table: Data<TFieldsKey>[]) =>
    table.map((row) => ({
      ...row,
      weight: parseToStringNumber(row.weight),
      length: parseToStringNumber(row.length),
      width: parseToStringNumber(row.width),
      height: parseToStringNumber(row.height),
      inventory: parseToStringNumber(row.inventory),
      inventory_low_level: parseToStringNumber(row.inventory_low_level),
      original_price: parseToStringNumber(row.original_price),
      price: parseToStringNumber(row.price),
    }));

  const rowHook = useCallback<RowHook<TFieldsKey>>(
    (currentRow, addError, table) => {
      // message must be trans path
      const onError = (key: TFieldsKey, message: string) => {
        return () => {
          addError(key, {
            message: t(message, { name: titleCase(key, '_') }),
            level: 'error',
          });
        };
      };

      // No duplicate variant name
      let match = 0;
      for (const row of table) {
        if (row.product_name !== currentRow.product_name) continue;
        if (row.variant_name === currentRow.variant_name) match++;
        if (match > 1) {
          validateFields(() => match > 1)(
            currentRow['variant_name'],
            onError('variant_name', 'form.invalid.duplicate_variants'),
          );
          break;
        }
      }

      // If value, value must not contain extra space
      fieldMustTrim.forEach((key) =>
        checkOutterSpace(
          currentRow[key],
          onError(key, 'form.invalid.extra_space'),
        ),
      );

      // Perform numeric checks on different fields
      fieldMustBeNumeric.forEach((key) =>
        checkStringNumeric(
          currentRow[key],
          onError(key, 'form.invalid.only_allow_number'),
        ),
      );

      // 'original_price' must be greater than 'price'
      checkSecondGreaterThanFirst(
        currentRow['original_price'],
        onError(
          'original_price',
          `Original Price ${t('form.invalid.greater_than_equal')} Price`,
        ),
        currentRow['price'] as string,
      );

      // Original price must be greater than 0
      checkNumberGreaterThanZero(
        currentRow['original_price'],
        onError(
          'original_price',
          `Original Price ${t('form.invalid.greater_than_0')}`,
        ),
      );

      //  Price must be greater than 0
      checkNumberGreaterThanZero(
        currentRow['price'],
        onError('price', 'Price ' + t('form.invalid.greater_than_0')),
      );

      //  Inventory must be greater than 0
      checkNumberGreaterThanZero(
        currentRow['inventory'],
        onError('inventory', 'Inventory ' + t('form.invalid.greater_than_0')),
      );

      checkWeightUnit(
        currentRow['weight_unit'],
        onError(
          'weight_unit',
          t('form.invalid.invalid', { fieldName: 'Weight Unit' }),
        ),
      );

      checkDimensionUnit(
        currentRow['dimension_unit'],
        onError(
          'dimension_unit',
          t('form.invalid.invalid', { fieldName: 'Dimension Unit' }),
        ),
      );

      checkImage(
        currentRow['main_image'],
        onError(
          'main_image',
          t('form.invalid.invalid', { fieldName: 'Main Image' }),
        ),
      );

      checkImage(
        currentRow['image_1'],
        onError('image_1', t('form.invalid.invalid', { fieldName: 'Image 1' })),
      );

      return { ...currentRow };
    },
    [i18n.language],
  );

  const handleSubmitButton = async (data: Result<TFieldsKey>) => {
    setErrors({});
    let inValid = false;
    const { validData } = data;

    const storeNames = [];
    const brands = [];
    const categories = [];
    const subCategories = [];

    for (const row of validData) {
      storeNames.push(row.store_name as string);
      brands.push(row.brand_name as string);
      categories.push(row.category as string);
      subCategories.push(row.subcategory as string);
    }

    const validateData = {
      store_names: Array.from(new Set(storeNames)),
      brand_names: Array.from(new Set(brands)),
      category_names: Array.from(new Set(categories)),
      sub_category_names: Array.from(new Set(subCategories)),
    };

    await validateImport(validateData, {
      onSuccess({ data = {} }) {
        if (keysFromObject(data).length === 0) return;
        inValid = true;
        setErrors(data);
        setShowFinishModal(true);
      },
      onError() {
        toast.error(t(DEFAULT_ERROR_MSG));
      },
    });

    if (inValid) return;

    const productsNames = validData.map((row) => row.product_name);
    const productsCount = new Set(productsNames).size;

    const headers = keysFromObject(validData[0]).map((key) =>
      titleCase(key, '_'),
    );

    const formattedValidData = validData.map((row) => ({
      ...row,
      weight: parseFloatFromString(row.weight),
      length: parseFloatFromString(row.length),
      width: parseFloatFromString(row.width),
      height: parseFloatFromString(row.height),
      inventory: parseFloatFromString(row.inventory),
      inventory_low_level: parseFloatFromString(row.inventory_low_level),
      original_price: parseFloatFromString(row.original_price),
      price: parseFloatFromString(row.price),
    }));

    const excel = new ExcelJS();

    const userName =
      user.first_name && user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.first_name
        ? user.first_name
        : user.last_name
        ? user.last_name
        : undefined;

    const excelName = userName
      ? `${userName}_${dayjs().format()}.xlsx`
      : `${dayjs().format()}.xlsx`;

    excel.addSheet(SHEET_NAME, formattedValidData, headers);
    const excelFile = excel.createFile(excelName);
    const id = await createImgID(excelFile, FOLDER_NAME, false, null);

    if (!id) {
      toast.error(t(DEFAULT_ERROR_MSG));
      return;
    }

    const createHistoryData = {
      product_count: productsCount,
      system_user_id: user._id,
      file_import_id: id,
    };

    createHistory(createHistoryData, {
      onSuccess(res) {
        if (!res?.status) throw new Error();
        queryClient.invalidateQueries({
          queryKey: importProductExcelKeys._def,
        });
        setShowFinishModal(true);
      },
      onError() {
        toast.error(t(DEFAULT_ERROR_MSG));
      },
    });
  };

  return (
    <>
      <SkeletonWrapper>
        <PageLayout
          title={title}
          breadCrumds={[
            { title: t('products.products'), href: '/products' },
            { title },
          ]}
        >
          <SectionWrapper>
            <SectionHeader
              title={title}
              subtitle={t('products.build_import_subtitle')}
              actionButtons={
                <>
                  <Button
                    variant="subtle"
                    leftSection={<IconFA icon={faDownload} />}
                    onClick={handlDownloadTemplate}
                  >
                    {t('products.download_template')}
                  </Button>

                  <Button
                    leftSection={<IconFA icon={faPlus} />}
                    onClick={() => setShowSheet(true)}
                  >
                    {t('products.upload_file')}
                  </Button>
                </>
              }
            />
            <SectionBody>
              <CommonTable
                loading={isLoading}
                data={tableData}
                columns={tableColumns}
                pagination={{ ...pagination, total }}
              />
            </SectionBody>
          </SectionWrapper>
        </PageLayout>
      </SkeletonWrapper>

      {showSheet && (
        <ReactSpreadSheet<TFieldsKey>
          key={'ValidateProducts'}
          isOpen={showSheet}
          onClose={() => {
            if (!validatingImport) setShowSheet(false);
          }}
          fields={reactSpreadSheetFields}
          allowInvalidSubmit={false}
          isNavigationEnabled
          rowHook={rowHook}
          matchColumnsStepHook={matchColumnsStepHook}
          onSubmit={handleSubmitButton}
        />
      )}

      {showFinishModal && (
        <Modal
          centered
          size="lg"
          closeOnClickOutside={false}
          closeOnEscape={false}
          opened={showFinishModal}
          onClose={() => setShowFinishModal(false)}
          title={
            <Title
              className={isValidateError ? 'text-red-600' : 'text-success'}
            >
              {isValidateError
                ? t('products.validated_with_errors')
                : t('products.validated_success')}
            </Title>
          }
        >
          {isValidateError
            ? entriesFromObject(errors).map(([key, value]) => {
                let displayKey;
                switch (key) {
                  case 'sellers':
                    displayKey = t('products.sellers_not_found');
                    break;
                  case 'brands':
                    displayKey = t('products.brands_not_found');
                    break;
                  case 'categories':
                    displayKey = t('products.categories_not_found');
                    break;
                  case 'subcategories':
                    displayKey = t('products.subcategories_not_found');
                    break;
                  case 'sellers_with_multiple_warehouses':
                    displayKey = t('products.sellers_with_multi_warehouse');
                    break;
                  case 'sellers_without_warehouse':
                    displayKey = t('products.sellers_without_warehouse');
                    break;
                  default:
                    displayKey = capitalize(key);
                }
                return (
                  <div key={displayKey}>
                    - {displayKey}: {value?.join(', ')}
                  </div>
                );
              })
            : t('products.file_is_uploaded')}
        </Modal>
      )}

      {deleteItem && (
        <DeleteModal
          name={deleteItem?.media_name}
          onShow={!!deleteItem}
          onClose={() => setDeleteItem(null)}
          onDelete={() => handleDeleteBtn(deleteItem?.id)}
        />
      )}
    </>
  );
};

export default BulkImportProducts;

const ImportStatusBadge = ({
  import_status,
}: {
  import_status: ImportHistoryStatus;
}) => {
  const statusColor = {
    [ImportHistoryStatus.VALIDATED]: 'blue',
    [ImportHistoryStatus.IMPORTED]: 'green',
    [ImportHistoryStatus.ERROR]: 'red',
  };
  const statusText = {
    [ImportHistoryStatus.VALIDATED]: t('general.validated'),
    [ImportHistoryStatus.IMPORTED]: t('general.imported'),
    [ImportHistoryStatus.ERROR]: t('general.error'),
  };

  return (
    <Badge
      size="xs"
      variant="light"
      fullWidth
      color={statusColor[import_status]}
    >
      {statusText[import_status]}
    </Badge>
  );
};
