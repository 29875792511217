import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAdd,
  faArrowCircleRight,
  faArrowDown,
  faArrowDownShortWide,
  faArrowUp,
  faBarcode,
  faBars,
  faBell,
  faBook,
  faBriefcase,
  faCalendar,
  faCalendarDay,
  faCartShopping,
  faChairOffice,
  faChevronDown,
  faChevronUp,
  faClose,
  faCog,
  faCommentDots,
  faCopy,
  faDoorOpen,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilter,
  faGear,
  faGraduationCap,
  faHandshake,
  faHeart,
  faHome,
  faInfoCircle,
  faLanguage,
  faLocationDot,
  faMessageCaptions,
  faMessageLines,
  faMoneyBill,
  faMoneyBill1,
  faNewspaper,
  faNoteSticky,
  faPhone,
  faPlusCircle,
  faQuestionCircle,
  faRuler,
  faSearch,
  faShareNodes,
  faShieldCheck,
  faShieldQuartered,
  faShippingFast,
  faShop,
  faShoppingBag,
  faSignInAlt,
  faSort,
  faStar,
  faStore,
  faTablePicnic,
  faTasksAlt,
  faThumbsUp,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserCircle,
  faUserFriends,
  faUserTie,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faAdd,
  faSearch,
  faEnvelope,
  faCommentDots,
  faMoneyBill1,
  faCalendar,
  faUserFriends,
  faClose,
  faHome,
  faMessageCaptions,
  faHandshake,
  faInfoCircle,
  faPhone,
  faQuestionCircle,
  faShippingFast,
  faCopy,
  faShieldQuartered,
  faLanguage,
  faUserCircle,
  faBell,
  faHeart,
  faShoppingBag,
  faTasksAlt,
  faUserTie,
  faDoorOpen,
  faBook,
  faCog,
  faSignInAlt,
  faChevronUp,
  faBars,
  faLocationDot,
  faMoneyBill,
  faCalendarDay,
  faUsers,
  faArrowCircleRight,
  faBarcode,
  faEye,
  faEyeSlash,
  faFilter,
  faArrowDown,
  faArrowUp,
  faSort,
  faPlusCircle,
  faChevronDown,
  faCartShopping,
  faBriefcase,
  faUser,
  faGraduationCap,
  faShop,
  faShareNodes,
  faStar,
  faRuler,
  faChairOffice,
  faTablePicnic,
  faNewspaper,
  faMessageLines,
  faStore,
  faThumbsUp,
  faTrashCan,
  faGear,
  faShieldCheck,
);

export const lightIcons = {
  add: faAdd,
  search: faSearch,
  envelope: faEnvelope,
  'comment-dots': faCommentDots,
  'money-bill-1': faMoneyBill1,
  calendar: faCalendar,
  'user-friends': faUserFriends,
  close: faClose,
  home: faHome,
  'message-captions': faMessageCaptions,
  handshake: faHandshake,
  'info-circle': faInfoCircle,
  phone: faPhone,
  'question-circle': faQuestionCircle,
  'shipping-fast': faShippingFast,
  copy: faCopy,
  'shield-quartered': faShieldQuartered,
  language: faLanguage,
  'user-circle': faUserCircle,
  bell: faBell,
  heart: faHeart,
  'shopping-bag': faShoppingBag,
  'tasks-alt': faTasksAlt,
  'user-tie': faUserTie,
  'door-open': faDoorOpen,
  book: faBook,
  cog: faCog,
  'sign-in-alt': faSignInAlt,
  'chevron-up': faChevronUp,
  bars: faBars,
  'location-dot': faLocationDot,
  'money-bill': faMoneyBill,
  'calendar-day': faCalendarDay,
  users: faUsers,
  'arrow-circle-right': faArrowCircleRight,
  barcode: faBarcode,
  eye: faEye,
  'eye-slash': faEyeSlash,
  filter: faFilter,
  'arrow-down': faArrowDown,
  'arrow-up': faArrowUp,
  sort: faSort,
  'plus-circle': faPlusCircle,
  'chevron-down': faChevronDown,
  'cart-shopping': faCartShopping,
  briefcase: faBriefcase,
  user: faUser,
  'graduation-cap': faGraduationCap,
  shop: faShop,
  'share-nodes': faShareNodes,
  star: faStar,
  ruler: faRuler,
  'chair-office': faChairOffice,
  'table-picnic': faTablePicnic,
  newspaper: faNewspaper,
  'message-lines': faMessageLines,
  store: faStore,
  'thumbs-up': faThumbsUp,
  'trash-can': faTrashCan,
  'arrow-down-short-wide': faArrowDownShortWide,
  gear: faGear,
  'note-sticky': faNoteSticky,
  edit: faEdit,
  'triangle-exclamation': faTriangleExclamation,
  'shield-check': faShieldCheck,
};
