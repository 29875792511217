import { auth } from 'firebase-app';
import {
  browserSessionPersistence,
  setPersistence,
  signInWithPhoneNumber,
  unlink
} from 'firebase/auth';

export const useSignInWithPhone = () => {
  const sendOtp = async (phoneNumber, recaptchaVerifier) => {
    try {
      await setPersistence(auth, browserSessionPersistence);

      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptchaVerifier
      );

      return result;
    } catch (error) {
      console.error('Error sending verification code:', error.message);
      throw error;
    }
  };

  const signIn = async (verificationCode, confirmationResult) => {
    try {
      if (confirmationResult) {
        // Confirm the verification code
        const result = await confirmationResult.confirm(verificationCode);
        return result;
      } else {
        console.error('No confirmation result available');
      }
    } catch (error) {
      console.error('Phone sign-in failed:', error.message);
    }
  };

  const unlinkAccount = async () => {
    try {
      await unlink(auth.currentUser, 'phone');
    } catch (error) {
      console.error('Phone unlink failed:', error);
    }
  };

  const ReCaptchaContainer = () => <div id="recaptcha-container"></div>;

  return { sendOtp, signIn, ReCaptchaContainer, unlinkAccount };
};
