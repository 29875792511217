import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DiscardWarningModal from './DiscardWarningModal';
import { Modal } from 'react-bootstrap';

/**
 * Create Modal with pop-up warning if there is any change.
 * Param must have Form component as ValueForm.
 * @param {bool} value
 * @param {dispatch} setValue
 * @param {bool} show
 * @param {dispatch} setShow
 * @param {Form func component} ValueForm
 * @returns JSX
 */
export const AddEditModal = React.memo(
  ({
    value,
    setValue,
    show,
    setShow,
    refetch,
    ValueForm,
    size = 'md',
    onHide,
    ...rest
  }) => {
    // Show warning if user changes data
    const [isChanged, setIsChanged] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const handleCloseWarning = () => setShowWarning(false);

    const handleResetState = () => {
      setShowWarning(false);
      setIsChanged(false);
      setShow(false);
      setValue(null);
    };

    const handleClose = () => {
      if (isChanged) {
        setShowWarning(true);
      } else {
        handleResetState();
      }
    };

    return (
      <React.Fragment>
        <Modal
          onHide={onHide ? handleClose : () => null}
          show={show}
          centered
          size={size}
          style={showWarning ? { filter: 'blur(2px)' } : null}
        >
          {ValueForm && (
            <ValueForm
              {...rest}
              value={value}
              refetch={refetch}
              handleClose={handleClose}
              setIsChanged={setIsChanged}
              handleResetState={handleResetState}
            />
          )}
        </Modal>

        <DiscardWarningModal
          showDiscardWarning={showWarning}
          handleCloseModal={handleCloseWarning}
          goBack={handleResetState}
        />
      </React.Fragment>
    );
  }
);

AddEditModal.propTypes = {
  onHide: PropTypes.bool,
  setShow: PropTypes.func,
  value: PropTypes.object,
  size: PropTypes.string,
  show: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired
};
